import React, {memo, useEffect, useState} from "react";
import {AutoComplete} from "../../widgets";
import CompareCompaniesBarChart from "./CompareCompaniesBarChart";
import {getComparison} from "../../../api/admin";
import {getSubmissionData} from "../../../api/client";

const CompaniesDropdown = ({
  currentCompany,
  title,
  submissionData,
  type,
  setCanvasSelectedCompany,
}) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (
      (type === "comparison" && selectedCompanies && selectedCompanies.length > 0) ||
      (type === "canvas" && selectedCompanies)
    ) {
      setCanvasSelectedCompany(selectedCompanies);
    }
  }, [selectedCompanies]);

  useEffect(() => {
    getComparison()
      .then((res) => {
        res.map((r) => {
          r.processedValues = {
            essentialElement1: parseInt(r.processedValues["essentialElement1"], 10),
            essentialElement2: parseInt(r.processedValues["essentialElement2"], 10),
            grossMargin: parseInt(r.processedValues["grossMargin"], 10),
            marketFit: parseInt(r.processedValues["marketFit"], 10),
            marketSize: parseInt(r.processedValues["marketSize"], 10),
            organizationalScalability: parseInt(
              r.processedValues["organizationalScalability"],
              10
            ),
            operationalScalability: parseInt(
              r.processedValues["operationalScalability"],
              10
            ),
          };
          if (r.processedValues["organizationalStage"]) {
            delete r.processedValues["organizationalStage"];
          }
        });
        return res;
      })
      .then((res) => {
        // remove only show visible admin companies without current company
        if (type === "comparison") {
          return res.filter((r) => r._id.toString() !== currentCompany.toString());
        } else if (res.find((r) => r._id === currentCompany)) {
          return res;
        } else {
          // add current company to visible admin companies
          return getSubmissionData({submissionID: currentCompany})
            .then((curRes) => {
              return [curRes.submissionObj, ...res];
            })
            .catch((error) => console.error(error));
        }
      })
      .then((res) => setCompanies(res))
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <AutoComplete
        title={title}
        type={type}
        companies={companies}
        setSelected={setSelectedCompanies}
        currentCompany={currentCompany}
      />
      {submissionData && (
        <CompareCompaniesBarChart
          processedValues={submissionData.processedValues}
          companyName={submissionData.companyName}
          selectedCompanies={selectedCompanies}
        />
      )}
    </>
  );
};

export default memo(CompaniesDropdown);
