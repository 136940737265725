import moment from "moment";

export const getLocalDateTime = (date) => {
  const dateUtc = moment.utc(date);
  const localDate = moment(dateUtc).local();
  return localDate.format("YYYY-MM-DD HH:mm:ss");
};

export const getRandomColor = () => {
  const o = Math.round;
  const r = Math.random;
  const s = 255;

  const getColor = (opacity) => {
    return `rgba(${o(r() * s)}, ${o(r() * s)}, ${o(r() * s)}, ${opacity})`;
  };

  return getColor(1);
};

export const getRandomColorArr = (length) => {
  return [...Array(length)].map((item) => getRandomColor());
};
