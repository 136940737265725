import React, {memo, useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {getTotalScoreStatistics} from "../../../api/admin";
import {getData} from "./colors";

const TotalScore = (submissionType) => {
  const [totalScore, setTotalScore] = useState([]);
  useEffect(() => {
    getTotalScoreStatistics(submissionType).then((data) => {
      setTotalScore(data);
    });
  }, [submissionType]);
  const labels = totalScore.map((c) => `${c._id}-${c._id + 10}`) || [];
  const data = totalScore.map((c) => c.count) || [];

  return <Pie data={getData({labels, data})} type="pie" />;
};

export default memo(TotalScore);
