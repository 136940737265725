import {makeStyles, withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    fontSize: 20,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -5,
    marginLeft: 10,
    border: "1px solid #dadde9",
    borderRadius: 50,
    "&:hover": {
      background: "#dadde9",
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ToolTips = ({text}) => {
  const classes = useStyles();
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <span>{text}</span>
        </React.Fragment>
      }
    >
      <InfoOutlinedIcon className={classes.tooltip} />
    </HtmlTooltip>
  );
};

export default ToolTips;
