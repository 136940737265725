import React from "react";
import PageWrapper from "../components/widgets/layout/PageWrapper";
import {useHistory} from "react-router-dom";
import Canvas from "../assets/images/home_gsa.png";
import {Button, Title} from "../components/widgets";
import styled from "styled-components";
import {Container} from "@material-ui/core";
import {device} from "../utils/breakpoints";
import Box from "@material-ui/core/Box";
import HomepageBG from "../assets/images/HomepageBG.gif";

function HomePage() {
  const history = useHistory();
  return (
    <PageWrapper
      dark={true}
      style={{
        backgroundImage: `url(${HomepageBG})`,
        height: "100%",
        margin: 0,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <div style={{margin: "50px auto 50px auto"}}>
        <Container
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Container style={{flexDirection: "column", justifyContent: "flex-start"}}>
            <Box component="span">
              <Title variant="home">
                The Blitzscalability Grader <span style={{fontSize: "22px"}}>TM</span>
              </Title>
              <h2 style={{color: "#f0f8ff"}}>
                The Blitzscalability Grader is a tool used to evaluate companies’
                scalability. We use it to dissect the success of today’s leading
                companies, and to analyze the potential of the next generation of
                blitzscalers.
              </h2>
              <h2 style={{color: "#f0f8ff"}}>
                Now, you can use it to evaluate your own business in minutes and get a
                free report containing insights to help you increase your scaling.
              </h2>
              <div
                style={{
                  marginTop: 80,
                  marginBottom: 80,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "250px",
                      wordWrap: "break-word",
                      color: "white",
                    }}
                  >
                    Ready to get your free report?
                  </span>
                  <Button
                    mb={3}
                    bg="#6d8a97"
                    style={{marginTop: 20}}
                    onClick={() => {
                      history.push("/blitzscalability-form");
                    }}
                  >
                    Get Started
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "250px",
                      wordWrap: "break-word",
                      color: "white",
                    }}
                  >
                    Want a Professional Blitzscalability Analysis of Your Company?
                  </span>
                  <Button
                    mb={3}
                    bg="#6d8a97"
                    style={{marginTop: 20}}
                    // onClick={() => {}}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            </Box>
          </Container>
          <Container style={{flexDirection: "column"}}>
            <ImgRight
              src={Canvas}
              alt=""
              style={{
                borderRadius: 5,
                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
              }}
            />
          </Container>
        </Container>
      </div>
    </PageWrapper>
  );
}

const ImgRight = styled.img`
  justify-content: flex-end;
  max-width: 80%;
  @media ${device.sm} {
    max-width: 80%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

export default HomePage;
