import axios from "./http-common";

export async function submitForm(formData) {
  try {
    const result = await axios.post("/form", formData);
    if (result) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getSubmissionData({submissionID}) {
  try {
    const result = await axios(`/submission/${submissionID}`);
    // console.log("submission", result);
    if (result) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
  }
}
