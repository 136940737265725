import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import {Container, Paper, Fade} from "@material-ui/core";
import Tabs from "./canvas-bar-tabs";
import "./blitzscalability-result.css";
import {jsPDF} from "jspdf";
import domtoimage from "dom-to-image";
import Loading from "../../widgets/loading/Loading";
import Logo from "../../widgets/logo/Logo";
import {ALL, FIRST, SECOND} from "../../common/result-model";
import {PrimaryButton} from "../../widgets";
import {getSubmissionData} from "../../../api/client";
import PageWrapper from "../../widgets/layout/PageWrapper";
import {
  RESULT_CANVAS_DROPDOWN_TITLE,
  RESULT_CANVAS_TITLE,
  RESULT_RADAR_DROPDOWN_TITLE,
} from "../../../utils/constants";
import CompaniesDropdown from "./CompaniesDropdown";
import ClientCanvas from "../../common/canvas/ClientCanvas";
import AdminCanvas from "../../common/canvas/AdminCanvas";
import CanvasPdfDescription from "../../common/canvas/CanvasPdfDescription";

export default function BlitzscalabilityResults() {
  const [submissionData, setSubmissionData] = useState();
  const [oriSubmissionData, setOriSubmissionData] = useState();
  const [displayCanvas, setDisplayCanvas] = useState(0);
  const [loading, setLoading] = useState(true);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [canvasSelectedCompany, setCanvasSelectedCompany] = useState([]);

  const {submissionID} = useParams();

  useEffect(() => {
    const fetchData = async () => {
      getSubmissionData({submissionID})
        .then((res) => {
          setSubmissionData(res.submissionObj);
          setOriSubmissionData(res.submissionObj);
        })
        .catch((error) => console.error(error));

      setTimeout(function () {
        setLoading(false);
      }, 1500);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (canvasSelectedCompany && canvasSelectedCompany._id) {
      setSubmissionData(canvasSelectedCompany);
    }
  }, [canvasSelectedCompany]);

  if (submissionData === null) {
    return <h1 style={{textAlign: "center"}}>Submission cannot be found!</h1>;
  }

  const printDocument = async () => {
    const canva1 = document.getElementById("canvas-section1");
    const canva2 = document.getElementById("canvas-section2");
    const enhancerQaContainer = document.getElementById("enhancer-qa-container");
    const limiterQaContainer = document.getElementById("limiter-qa-container");
    const logo = document.getElementById("pdf-nav");
    const title = document.getElementById("pdf-title");
    // const footer = document.getElementById("footer");

    const options = {background: "white", quality: 1, width: 1300};
    const logoOptions = {...options, height: 100};
    const titleOptions = {...options, height: 120};
    const canvas1Options = {...options, height: 690};
    const canvas2Options = {...options, height: 690};
    const enhancerQaOptions = {...options, height: 1600};
    const limiterQaOptions = {...options, height: 1600};
    // const footerOptions = {...options, height: 100};

    const doc = new jsPDF("p", "mm", "a4");

    // Logo PNG
    const dataUrlLogo = await domtoimage.toPng(logo, logoOptions);
    const imgPropsLogo = doc.getImageProperties(dataUrlLogo);
    const pdfWidthLogo = doc.internal.pageSize.getWidth();
    const pdfHeightLogo = (imgPropsLogo.height * pdfWidthLogo) / imgPropsLogo.width;

    // Title PNG
    const dataUrlTitle = await domtoimage.toPng(title, titleOptions);
    const imgPropsTitle = doc.getImageProperties(dataUrlTitle);
    const pdfWidthTitle = doc.internal.pageSize.getWidth();
    const pdfHeightTitle = (imgPropsTitle.height * pdfWidthTitle) / imgPropsTitle.width;

    // Footer PNG
    // const dataUrlFooter = await domtoimage.toPng(footer, footerOptions);
    // const imgPropsFooter = doc.getImageProperties(dataUrlFooter);
    // const pdfWidthFooter = doc.internal.pageSize.getWidth();
    // const pdfHeightFooter =
    //   (imgPropsFooter.height * pdfWidthFooter) / imgPropsFooter.width;

    // _canva PNG
    const dataUrlCanva = await domtoimage.toPng(canva1, canvas1Options);
    const imgPropsCanvas = doc.getImageProperties(dataUrlCanva);
    const pdfWidthCanvas = doc.internal.pageSize.getWidth();
    const pdfHeightCanvas =
      (imgPropsCanvas.height * pdfWidthCanvas) / imgPropsCanvas.width;

    // Canva2 PNG
    const dataUrlCanva2 = await domtoimage.toPng(canva2, canvas2Options);
    const imgPropsCanvas2 = doc.getImageProperties(dataUrlCanva2);
    const pdfWidthCanvas2 = doc.internal.pageSize.getWidth();
    const pdfHeightCanvas2 =
      (imgPropsCanvas2.height * pdfWidthCanvas2) / imgPropsCanvas2.width;

    // enhancer QA container PNG
    const dataUrlEnhancerQa = await domtoimage.toPng(
      enhancerQaContainer,
      enhancerQaOptions
    );
    const imgPropsEnhancerQa = doc.getImageProperties(dataUrlEnhancerQa);
    const pdfWidthEnhancerQa = doc.internal.pageSize.getWidth();
    const pdfHeightEnhancerQa =
      (imgPropsEnhancerQa.height * pdfWidthEnhancerQa) / imgPropsEnhancerQa.width;

    // limiter qa container PNG
    const dataUrlLimiterQa = await domtoimage.toPng(limiterQaContainer, limiterQaOptions);
    const imgPropsLimiterQa = doc.getImageProperties(dataUrlLimiterQa);
    const pdfWidthLimiterQa = doc.internal.pageSize.getWidth();
    const pdfHeightLimiterQa =
      (imgPropsLimiterQa.height * pdfWidthLimiterQa) / imgPropsLimiterQa.width;

    // page 1
    doc.addImage(dataUrlLogo, "PNG", 20, 10, pdfWidthLogo, pdfHeightLogo);
    doc.addImage(dataUrlTitle, "PNG", 20, 30, pdfWidthTitle, pdfHeightTitle);
    doc.addImage(dataUrlCanva, "PNG", 20, 60, pdfWidthCanvas, pdfHeightCanvas);
    doc.setFont("helvetica");
    doc.setFontSize(8);
    doc.rect(85, 181, 51, 0);
    doc.textWithLink("Based on the Official Blitzscaling Toolkit", 85, 180, {
      url: "https://www.blitzscalingacademy.com/resources",
    });
    doc.addImage(dataUrlCanva2, "PNG", 20, 190, pdfWidthCanvas2, pdfHeightCanvas2);
    // doc.addImage(dataUrlFooter, "PNG", 20, 1650, pdfWidthFooter, pdfHeightFooter);
    doc.text("page 1", 65, 1600);

    // page 2
    doc.addPage();
    doc.addImage(dataUrlLogo, "PNG", 20, 10, pdfWidthLogo, pdfHeightLogo);
    doc.addImage(
      dataUrlEnhancerQa,
      "PNG",
      20,
      30,
      pdfWidthEnhancerQa,
      pdfHeightEnhancerQa
    );
    doc.text("page 2", 65, 1600);

    // page 3
    doc.addPage();
    doc.addImage(dataUrlLogo, "PNG", 20, 10, pdfWidthLogo, pdfHeightLogo);
    doc.addImage(dataUrlLimiterQa, "PNG", 20, 30, pdfWidthLimiterQa, pdfHeightLimiterQa);
    doc.text("page 3", 65, 1600);

    doc.save(
      `${submissionData && submissionData.companyName}-Blitzscalability grader.pdf`
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <PageWrapper
      style={{
        paddingTop: "30px",
        paddingBottom: "150px",
      }}
    >
      <Fade in={true}>
        <Container
          maxWidth="lg"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Paper elevation={3} style={{padding: "50px"}}>
            <Tabs setDisplayCanvas={setDisplayCanvas} displayCanvas={displayCanvas} />
            {displayCanvas === 0 ? (
              <div id="canva-container">
                <div
                  id="pdf-nav"
                  style={{
                    overflow: "hidden",
                    height: "0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Logo width="200px" />
                  </div>
                </div>
                <div id="pdf-title">
                  <h3
                    style={{
                      textAlign: "center",
                      margin: "20px auto",
                    }}
                  >
                    {RESULT_CANVAS_TITLE}
                  </h3>
                  <h1
                    style={{
                      textAlign: "center",
                      margin: "30px auto",
                      fontSize: "50px",
                      fontWeight: "bold",
                      fontFamily: "Gibson SemiBold",
                    }}
                  >
                    {submissionData && submissionData.companyName}
                  </h1>
                </div>
                <CompaniesDropdown
                  currentCompany={submissionID}
                  title={RESULT_CANVAS_DROPDOWN_TITLE}
                  type="canvas"
                  setCanvasSelectedCompany={setCanvasSelectedCompany}
                />
                <div id="canvas-section1">
                  {submissionData && submissionData.submissionType === "admin" ? (
                    <AdminCanvas
                      pointsDistribution={
                        submissionData && submissionData.pointsDistribution
                      }
                    />
                  ) : (
                    <ClientCanvas
                      pointsDistribution={
                        submissionData && submissionData.pointsDistribution
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    margin: "10px auto",
                  }}
                >
                  <a
                    href="https://www.blitzscalingacademy.com/resources"
                    target="_blank"
                    style={{fontSize: "13px"}}
                  >
                    Based on the Official Blitzscaling Toolkit
                  </a>
                </div>
                <div id="canvas-section2">
                  <div
                    style={{
                      margin: "30px auto",
                      textAlign: "center",
                    }}
                  >
                    <h2
                      style={{
                        fontFamily: "Gibson SemiBold",
                        textAlign: "center",
                        fontSize: "30px",
                        marginTop: "10px",
                        marginBottom: "100px",
                      }}
                    >
                      Blitzscalability Score:{" "}
                      {submissionData && submissionData.blitzScalabilityScore}
                      /100
                    </h2>
                  </div>
                  <CanvasPdfDescription
                    roundedProcessedValues={
                      submissionData && submissionData.processedValues
                    }
                  />
                </div>
                <QAContainerList submissionData={submissionData} />
              </div>
            ) : (
              <>
                <div>
                  <h3
                    style={{
                      textAlign: "center",
                      margin: "40px auto",
                    }}
                  >
                    {RESULT_CANVAS_TITLE}
                  </h3>
                  <h1
                    style={{
                      textAlign: "center",
                      margin: "40px auto",
                      fontSize: "50px",
                      fontWeight: "bold",
                      fontFamily: "Gibson SemiBold",
                    }}
                  >
                    {oriSubmissionData && oriSubmissionData.companyName}
                  </h1>
                </div>
                <CompaniesDropdown
                  currentCompany={submissionID}
                  title={RESULT_RADAR_DROPDOWN_TITLE}
                  submissionData={oriSubmissionData}
                  setCanvasSelectedCompany={setCanvasSelectedCompany}
                  type="comparison"
                />
                <h2
                  style={{
                    fontFamily: "Gibson SemiBold",
                    textAlign: "center",
                    fontSize: "30px",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  The Blitzscalability Score is:{" "}
                  {oriSubmissionData && oriSubmissionData.blitzScalabilityScore}
                  /100
                </h2>
                <QAContainerList submissionData={oriSubmissionData} />
              </>
            )}
            <div id="get-pdf-container">
              {displayCanvas === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                >
                  <PrimaryButton
                    id="get-pdf"
                    type="button"
                    title={generatingPDF ? "Downloading..." : "Download as PDF"}
                    onClick={() => {
                      setGeneratingPDF(true);
                      printDocument();
                      setGeneratingPDF(false);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{fontSize: "22px", fontWeight: "bold"}}>
                Want a professional analysis of your startup’s potential to blitzscale?
              </p>
              <PrimaryButton type="button" title="Learn more" />
            </div>
          </Paper>
        </Container>
      </Fade>
    </PageWrapper>
  );
}

const QAContainerList = ({submissionData}) => {
  if (!submissionData) return null;
  return (
    <>
      <QAContainer
        list={[...ALL]}
        submissionData={submissionData}
        isPdf={false}
        keyId="total"
      />
      <QAContainer
        list={[...FIRST]}
        submissionData={submissionData}
        isPdf={true}
        keyId="enhancer"
      />
      <QAContainer
        list={[...SECOND]}
        submissionData={submissionData}
        isPdf={true}
        keyId="limiter"
      />
    </>
  );
};

const QAContainer = ({list, submissionData, isPdf, keyId}) => {
  if (submissionData && !submissionData.processedValues) return null;
  if (submissionData && !submissionData.interpretation) return null;
  const isAdmin = submissionData.submissionType === "admin" || false;
  const isClient = submissionData.submissionType === "client" || false;
  const processedValues = submissionData.processedValues;
  const interpretation = submissionData.interpretation;
  return (
    <div
      id={`${keyId}-qa-container`}
      style={isPdf ? {overflow: "hidden", height: "0"} : null}
    >
      {list.map((item, index) => {
        if (
          item.name === "organizationalStage" &&
          processedValues &&
          !processedValues[item.name]
        ) {
          return null;
        }

        let itemTitle = item.title;
        if (isAdmin) {
          itemTitle = itemTitle.split(":")[1];
          if (item.name === "essentialElement1" || item.name === "essentialElement2") {
            itemTitle += " (Growth Enhancer)";
          }
        }

        return (
          <div className={`${isPdf ? "pdf-" : ""}qa-section`} key={`${keyId}-${index}`}>
            <div className="score">
              <span className="qa-item-title">{itemTitle}</span>
              <span className="qa-item-title">
                {item.name === "organizationalStage"
                  ? processedValues && processedValues[item.name]
                  : `Score: ${processedValues && processedValues[item.name]}/${
                      item.totalScore
                    }`}
              </span>
            </div>
            <hr
              style={{
                color: "#6d8a97",
                backgroundColor: "#6d8a97",
                height: 2,
              }}
            />
            {isClient && (
              <>
                {item.multiQuestions && !submissionData.evaluation[item.name] ? (
                  item.items.map((i, index) => (
                    <div key={index}>
                      <div className="qa-item-container">
                        <span className="qa-headings qa-headings-title">{`Question ${String.fromCharCode(
                          index + 65
                        )} ${i.subtitle}: `}</span>
                        <span className="qa-headings">{i.question}</span>
                      </div>
                      <div className="qa-item-container">
                        <span className="qa-headings qa-headings-title">{`Your Answer: `}</span>
                        <span className="qa-headings">
                          {submissionData.evaluation[i.name]}
                        </span>
                      </div>
                      <div className="qa-item-container">
                        <span className="qa-headings qa-headings-title">{`Interpretation: `}</span>
                        <span className="qa-headings">{interpretation[i.name]}</span>
                      </div>
                      <br />
                    </div>
                  ))
                ) : (
                  <>
                    <div className="qa-item-container">
                      <span className="qa-headings qa-headings-title">
                        {"Question: "}
                      </span>
                      <span className="qa-headings">{item.question}</span>
                    </div>
                    <div className="qa-item-container">
                      <span className="qa-headings qa-headings-title">{`Your Answer: `}</span>
                      <span className="qa-headings">
                        {item.name === "organizationalStage"
                          ? submissionData["companySize"]
                          : null}
                        {item.options &&
                          item.options.find(
                            (o) =>
                              o.key.toString() ===
                              submissionData.evaluation[item.name].toString()
                          ).value}
                      </span>
                    </div>
                    <div className="qa-item-container">
                      <span className="qa-headings qa-headings-title">{`Interpretation: `}</span>
                      <span className="qa-headings">{interpretation[item.name]}</span>
                    </div>
                  </>
                )}
                {item.notes ? (
                  <span className="qa-headings qa-headings-notes">{item.notes}</span>
                ) : null}
              </>
            )}
            {isAdmin && (
              <>
                <div className="qa-item-container">
                  <span className="qa-headings qa-headings-title">{`Your Answer: `}</span>
                  <span className="qa-headings">
                    {submissionData.evaluation[item.name]}
                  </span>
                </div>
                <div className="qa-item-container">
                  <span className="qa-headings qa-headings-title">{`Interpretation: `}</span>
                  <span className="qa-headings">{interpretation[item.name]}</span>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
