import React from "react";
import {useTheme} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      style={{width: "60%", margin: "50px auto"}}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function StatisticsTabs({
  category,
  country,
  typeOfBusiness,
  numOfEmployees,
  revenueStage,
  fundingType,
  totalScore,
}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Category" />
        <Tab label="Country" />
        <Tab label="Type Of Business" />
        <Tab label="Num Of Employees" />
        <Tab label="Revenue Stage" />
        <Tab label="Funding Type" />
        <Tab label="Total Score" />
      </Tabs>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {category}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {country}
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        {typeOfBusiness}
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        {numOfEmployees}
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        {revenueStage}
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        {fundingType}
      </TabPanel>
      <TabPanel value={value} index={6} dir={theme.direction}>
        {totalScore}
      </TabPanel>
    </div>
  );
}
