import axios from "./http-common";

export async function getCategoryStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/category", {
      params: {
        submissionType: data.submissionType,
      },
    });
    // console.log("getCategoryStatistics data: ", result.data);
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getCountryStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/country", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getTypeOfBusinessStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/typeOfBusiness", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getNumOfEmployeesStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/numOfEmployees", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getRevenueStageStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/revenueStage", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getFundingTypeStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/fundingType", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return {
        data: result.data[0] && result.data[0].data,
        totalCount:
          result.data[0] &&
          result.data[0].totalCount &&
          result.data[0].totalCount[0] &&
          result.data[0].totalCount[0].totalCount,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getTotalScoreStatistics(data) {
  try {
    const result = await axios.get("/admin/statistics/totalScore", {
      params: {
        submissionType: data.submissionType,
      },
    });
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
  }
}

// export async function addAdminComment(company) {
//   try {
//     const result = await axios.post("/admin/comment", {
//       _id: company._id,
//       adminComment: company.adminComment,
//     });
//     if (result) {
//       return true;
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

export async function deleteSubmission(company) {
  try {
    await axios.delete("/admin/deleteSubmission", {
      params: {_id: company._id},
    });
  } catch (error) {
    console.error(error);
  }
}

export async function getComparison() {
  try {
    const result = await axios("/admin/comparison");
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function adminAddEditCompany(props) {
  try {
    const result =
      props.parsed || props.updateID
        ? await axios.post("/admin/editCompany", props)
        : await axios.post("/admin/addCompany", props);

    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getSearchResult({submissionTypes, ...query}) {
  try {
    return await axios.get("/admin/getAllSubmissions", {
      params: {
        ...query,
        submissionTypes: submissionTypes,
      },
    });
  } catch (error) {
    console.error(error);
  }
}
