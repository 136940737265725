import React from "react";
import Logo from "../logo/Logo";

const Header = ({darkLogo}) => {
  return (
    <div style={{marginTop: "50px", marginLeft: "80px", textAlign: "left"}}>
      <Logo dark={darkLogo} />
    </div>
  );
};

export default Header;
