import React from "react";
import BlitzscalabilityForm from "../../../components/client/blitzscalability-form/blitzscalability-form";
import {Container} from "@material-ui/core";
import bgrImage from "../../../assets/images/FundingStage.png";
import PageWrapper from "../../../components/widgets/layout/PageWrapper";
import {
  FORM_HEADER_DESCRIPTION1,
  FORM_HEADER_DESCRIPTION2,
  FORM_HEADER_DESCRIPTION_TITLE,
} from "../../../utils/constants";

function RenderForm() {
  return (
    <PageWrapper
      style={{
        backgroundImage: `url(${bgrImage})`,
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <Container maxWidth="md">
        <div className={"title-container"} style={{marginTop: "50px"}}>
          <h1 style={{fontSize: "50px", textAlign: "center"}}>
            The Blitzscalability Grader
          </h1>
          <div>
            <h2>{FORM_HEADER_DESCRIPTION_TITLE}</h2>
            <Descriptions
              title={FORM_HEADER_DESCRIPTION1.title}
              list={FORM_HEADER_DESCRIPTION1.list}
            />
            <Descriptions
              title={FORM_HEADER_DESCRIPTION2.title}
              list={FORM_HEADER_DESCRIPTION2.list}
            />
          </div>
        </div>
        <BlitzscalabilityForm />
      </Container>
    </PageWrapper>
  );
}

const Descriptions = ({title, list}) => {
  return (
    <div>
      <h4>{title}</h4>
      <ul>
        {list.map((l, index) => (
          <li key={index} style={{margin: "5px auto 5px auto", fontSize: "20px"}}>
            {l}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RenderForm;
