import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutoComplete({
  currentCompany,
  title,
  type,
  companies,
  setSelected,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifySelf: "center",
        alignItems: "center",
      }}
    >
      <h3>{title}</h3>
      <Autocomplete
        multiple={type === "comparison"}
        options={companies || []}
        getOptionLabel={(option) => {
          return option._id === currentCompany
            ? `${option.companyName} (Current)`
            : option.companyName;
        }}
        style={{width: 300}}
        renderInput={(params) => (
          <TextField {...params} label="Select" variant="outlined" />
        )}
        onChange={(e, val) => {
          setSelected(val);
        }}
      />
    </div>
  );
}
