import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: "#3d5467",
  },
  customTabIndicator: {
    backgroundColor: "#3d5467",
  },
}));

export default function RadarTabs({setDisplayCanvas, displayCanvas}) {
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setDisplayCanvas(newValue);
  };

  return (
    <Paper square>
      <Tabs
        value={displayCanvas}
        onChange={handleChange}
        variant="fullWidth"
        classes={{
          root: classes.customTabRoot,
          indicator: classes.customTabIndicator,
        }}
      >
        <Tab icon={<DashboardIcon />} label="BLITZSCALING CANVAS VIEW" />
        <Tab icon={<TrackChangesIcon />} label="BAR GRAPH VIEW" />
      </Tabs>
    </Paper>
  );
}
