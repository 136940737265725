import React from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import "./blitzscalability-form.css";
import CountryList from "../../widgets/dropdown/CountriesList";
import {useHistory} from "react-router-dom";
import clientFormSchema from "./client-form-schema";
import MODEL from "./client-business-model";
import DialogAlert from "../../widgets/alert/DialogAlert";
import {ToolTips, PrimaryButton} from "../../widgets";
import {submitForm} from "../../../api/client";

const placeHolder = {
  SCORE_YOUR_COMPANY: "Score Your Company",
  PLEASE_SELECT: "- Please Select -",
};
export default function BlitzscalabilityForm() {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleSubmit = async (formData) => {
    const requestData = {...formData, submissionType: "client"};
    submitForm(requestData)
      .then((response) => {
        // something wrong
        if (response === undefined || response === null || !response) {
          setOpenDialog(true);
          return;
        }
        const submissionID = response.submissionID;
        history.push(`/blitzscalability-results/${submissionID}`);
      })
      .catch((error) => {
        console.log(error);
        setOpenDialog(true);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          // firstName: "Client",
          // lastName: "Haa",
          // email: "test@test.com",
          // companyName: "vasd",
          // companyRole: "CEO",
          // website: "",
          // companySize: "1-9",
          // revenue: "Pre-Product",
          // support: "yes",
          // fundingType: "Seed",
          // category: "B2C",
          // business: "Marketplace",
          // networkEffects: "3",
          // landGrab: "3",
          // marketSize: "3",
          // distribution: "3",
          // virality: "3",
          // grossMargin: "3",
          // marketFit: "3",
          // organizationalScalability: "3",
          // operationalScalability: "3",
          // country: "",
          // clientComment: "client comments",
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          companyRole: "",
          website: "",
          companySize: "",
          revenue: "",
          support: "",
          fundingType: "",
          category: "",
          business: "",
          networkEffects: "",
          landGrab: "",
          marketSize: "",
          distribution: "",
          virality: "",
          grossMargin: "",
          marketFit: "",
          organizationalScalability: "",
          operationalScalability: "",
          country: "",
          clientComment: "",
        }}
        validationSchema={clientFormSchema}
        onSubmit={handleSubmit}
      >
        {({errors, touched}) => (
          <Form id="blitzscalability-form">
            <p style={{fontSize: "30px", fontWeight: "900", textAlign: "center"}}>
              {MODEL.sectionHeader.header1.title}
            </p>
            {getTextField(1, MODEL.firstName)}
            {getTextField(2, MODEL.lastName)}
            {getTextField(3, MODEL.email)}
            {getTextField(4, MODEL.companyName)}
            {getTextField(5, MODEL.website)}
            {getCountryList(6, MODEL.country)}
            {getDropdownLayout(7, MODEL.companyRole)}
            {getMultiQuestionField(8, MODEL.businessModel)}
            {getMultiQuestionField(9, MODEL.companyStage)}
            {getFieldsLayout(10, MODEL.support)}
            {getFieldsLayout(11, MODEL.fundingType)}

            <div>
              <p style={{fontSize: "30px", fontWeight: "900", textAlign: "center"}}>
                {MODEL.sectionHeader.header2.title}
              </p>
              <p className="form-description">{MODEL.sectionHeader.header2.subtitle}</p>
            </div>
            {/*question list*/}
            {MODEL.questionnaireList.map((model, index) => {
              return model.multiQuestions
                ? getMultiQuestionField(index + 12, model)
                : getQuestionField(index + 12, model);
            })}

            {getTextField(18, MODEL.clientComment, {
              component: "textarea",
              style: {width: "50%", marginBottom: "1.5rem"},
            })}

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                flexDirection: "horizontal",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{fontSize: "22px", fontWeight: "bold"}}>{MODEL.submit.title}</p>
              <PrimaryButton type="submit" title="SUBMIT" />
            </div>
            <div className="input-group" style={{width: "70%"}}>
              <p>
                {MODEL.submit.subTitle1}
                <a target="_blank" href="terms-and-conditions">
                  {MODEL.submit.subTitle2}
                </a>
                {MODEL.submit.subTitle3}
              </p>
            </div>
          </Form>
        )}
      </Formik>
      {openDialog ? (
        <DialogAlert
          title="Error"
          content="Something is wrong, please try again!"
          button="Ok"
          openDialog={openDialog}
        />
      ) : null}
    </div>
  );
}

const getDropdownLayout = (num, model) => {
  return (
    <div className="question-block input-block">
      {model.title ? (
        <label className="form-title" htmlFor={model.name}>
          {`${num}. ${model.title}`}
        </label>
      ) : null}
      <Field
        as="select"
        name={model.name}
        className="form-dropdown"
        style={model.title ? {} : {margin: "auto auto 10px 25px"}}
      >
        <option value="" disabled="" defaultValue="">
          {model.title ? placeHolder.PLEASE_SELECT : placeHolder.SCORE_YOUR_COMPANY}
        </option>
        {model.options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.value}
          </option>
        ))}
      </Field>
      <ErrorMessage className="form-error" component="span" name={model.name} />
    </div>
  );
};

const getFieldsLayout = (num, model) => {
  return (
    <div className="question-block">
      {model.title ? (
        <label className="form-title" htmlFor={model.name}>
          {`${num}. ${model.title}`}
        </label>
      ) : null}
      <div className="radio-group" role="group" aria-labelledby={model.name}>
        {model.options
          ? model.options.map((option, idx) => (
              <label key={idx} className="form-options">
                <Field type="radio" name={model.name} value={option.key} />
                {option.value}
              </label>
            ))
          : null}
      </div>
      <ErrorMessage className="form-error" component="span" name={model.name} />
    </div>
  );
};

const getTextField = (num, model, attr) => {
  return (
    <div
      className={`question-block ${model.name === "clientComment" ? "" : "input-block"}`}
    >
      <label className="form-title" htmlFor={model.name}>
        {`${num}. ${model.title}`}
      </label>
      <Field name={model.name} {...attr} />
      <ErrorMessage className="form-error" component="span" name={model.name} />
    </div>
  );
};

const getQuestionField = (num, model) => {
  return (
    <div key={num} className="question-block">
      <label className="form-title" htmlFor={model.name}>
        {`${num}. ${model.title}`}
        {model.tooltips ? <ToolTips text={model.tooltips} /> : null}
      </label>
      {model.description ? (
        <label className="form-description" htmlFor={model.name}>
          {model.description}
        </label>
      ) : null}
      <label className="form-question" htmlFor={model.name}>
        {model.question}
      </label>
      <div className="radio-group" role="group" aria-labelledby={model.name}>
        {model.options.map((option, idx) => (
          <label key={idx} className="form-options">
            <Field type="radio" name={model.name} value={option.key} />
            {option.value}
          </label>
        ))}
      </div>
      <ErrorMessage className="form-error" component="span" name={model.name} />
    </div>
  );
};

const getMultiQuestionField = (num, model) => {
  return (
    <div key={num} className="question-block">
      <label className="form-title" htmlFor={model.name}>
        {`${num}. ${model.title}`}
        {model.tooltips ? <ToolTips text={model.tooltips} /> : null}
      </label>
      {model.items.length <= 0
        ? null
        : model.items.map((item, index) => (
            <div key={index} style={{display: "flex", flexDirection: "column"}}>
              {item.subtitle ? (
                <label className="form-subtitle" htmlFor={item.name}>
                  {`${String.fromCharCode(index + 65)}. ${item.subtitle}`}
                </label>
              ) : null}
              {item.description ? (
                <label className="form-description" htmlFor={item.name}>
                  {item.description}
                </label>
              ) : null}
              {item.question ? (
                <label className="form-multi-question" htmlFor={item.name}>
                  {item.question}
                </label>
              ) : null}
              {item.descList ? (
                <label className="form-list" htmlFor={item.name}>
                  <ul>
                    {item.descList.map((l, idx) => (
                      <li
                        key={idx}
                        style={{margin: "3px auto 3px auto", fontSize: "20px"}}
                      >
                        {l.value}
                        {l.tooltips ? <ToolTips text={l.tooltips} /> : null}
                      </li>
                    ))}
                  </ul>
                </label>
              ) : null}
              {item.optionsDropDown
                ? getDropdownLayout(num, {
                    options: item.options,
                    name: item.name,
                  })
                : item.optionsField
                ? getFieldsLayout(num, {
                    options: item.options,
                    name: item.name,
                  })
                : null}
              <ErrorMessage className="form-error" component="span" name={model.name} />
            </div>
          ))}
    </div>
  );
};

const getCountryList = (num, model) => {
  return (
    <div className="question-block input-block">
      <label className="form-title" htmlFor={model.name}>
        {`${num}. ${model.title}`}
      </label>
      <Field as="select" name={model.name}>
        <option value="" disabled="" defaultValue="">
          - Please Select -
        </option>
        <CountryList />
      </Field>
      <ErrorMessage className="form-error" component="span" name={model.name} />
    </div>
  );
};
