import React, {useState} from "react";
import CompanyInfoTable from "../../components/admin/all-submissions/CompanyInfoTable";
import "./all-submission.css";
import CompanyInfoModal from "../../components/admin/all-submissions/CompanyInfoModal";

const AllSubmissionsPage = () => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [company, setCompany] = useState({});
  const [data, setData] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CompanyInfoTable
        setOpen={setOpen}
        setRowData={setRowData}
        setCompany={setCompany}
        rowData={rowData}
        data={data}
        setData={setData}
      />
      <CompanyInfoModal company={company} open={open} handleClose={handleClose} />
    </>
  );
};

export default AllSubmissionsPage;
