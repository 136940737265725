import React from "react";
import TableForm from "../../components/admin/add-well-known-companies/TableForm";
import {Typography} from "@material-ui/core";
import {
  ADMIN_ADD_WELL_KNOWN_COMPANIES_TITLE,
  ADMIN_EDIT_WELL_KNOWN_COMPANIES_TITLE,
} from "../../utils/constants";

function AddOrEditCompanies(props) {
  const editCompanyInfo =
    (props &&
      props.location &&
      props.location.state &&
      props.location.state.data &&
      props.location.state.data) ||
    false;
  let tableFormProps = {};
  if (editCompanyInfo) {
    tableFormProps.companyInfo = editCompanyInfo;
  }

  return (
    <div
      style={{
        padding: 16,
        margin: "auto",
        maxWidth: "90%",
        justifySelf: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" align="center" component="h2" gutterBottom>
        {editCompanyInfo
          ? ADMIN_EDIT_WELL_KNOWN_COMPANIES_TITLE
          : ADMIN_ADD_WELL_KNOWN_COMPANIES_TITLE}
      </Typography>
      <TableForm {...tableFormProps} />
    </div>
  );
}

export default AddOrEditCompanies;
