import React from "react";
import MODEL from "../client/blitzscalability-form/client-business-model";
import {NOTE} from "../../utils/constants";

const companySizeItem =
  MODEL.companyStage.items.find((item) => item.name === "companySize") || {};
const essentialElement1 =
  MODEL.questionnaireList.find((item) => item.name === "essentialElement1") || {};
const networkEffects =
  (essentialElement1 &&
    essentialElement1.items &&
    essentialElement1.items.find((item) => item.name === "networkEffects")) ||
  {};
const landGrab =
  (essentialElement1 &&
    essentialElement1.items &&
    essentialElement1.items.find((item) => item.name === "landGrab")) ||
  {};
const essentialElement2 =
  MODEL.questionnaireList.find((item) => item.name === "essentialElement2") || {};
const distribution =
  (essentialElement2 &&
    essentialElement2.items &&
    essentialElement2.items.find((item) => item.name === "distribution")) ||
  {};
const virality =
  (essentialElement2 &&
    essentialElement2.items &&
    essentialElement2.items.find((item) => item.name === "virality")) ||
  {};
const marketSize =
  MODEL.questionnaireList.find((item) => item.name === "marketSize") || {};
const grossMargin =
  MODEL.questionnaireList.find((item) => item.name === "grossMargin") || {};
const marketFit = MODEL.questionnaireList.find((item) => item.name === "marketFit") || {};
const organizationalScalability =
  MODEL.questionnaireList.find((item) => item.name === "organizationalScalability") || {};
const operationalScalability =
  MODEL.questionnaireList.find((item) => item.name === "operationalScalability") || {};

export const FIRST = [
  {
    name: "organizationalStage",
    title: "Your organizational stage is",
    question: companySizeItem && companySizeItem.subtitle,
  },
  {
    name: "essentialElement1",
    multiQuestions: true,
    totalScore: 29,
    title: essentialElement1 && essentialElement1.title,
    items: [
      {
        name: "networkEffects",
        subtitle: networkEffects && networkEffects.subtitle,
        question: networkEffects && networkEffects.question,
      },
      {
        name: "landGrab",
        subtitle: landGrab && landGrab.subtitle,
        question: landGrab && landGrab.question,
      },
    ],
    notes: NOTE,
  },
  {
    name: "essentialElement2",
    multiQuestions: true,
    totalScore: 29,
    title: essentialElement2 && essentialElement2.title,
    items: [
      {
        name: "distribution",
        subtitle: distribution && distribution.subtitle,
        question: distribution && distribution.question,
      },
      {
        name: "virality",
        subtitle: virality && virality.subtitle,
        question: virality && virality.question,
      },
    ],
    notes: NOTE,
  },
];

export const SECOND = [
  {
    name: "marketSize",
    title: marketSize && marketSize.title,
    totalScore: 11,
    question: marketSize && marketSize.question,
    options: [
      {key: "5", value: "Essentially unbounded"},
      {key: "4", value: "Very large (measured in the $10s of billions)"},
      {key: "3", value: "Large (measured in the $1s of billions)"},
      {key: "2", value: "Will be in the $1s billions in a few years"},
      {
        key: "1",
        value: "Will always be less than a $1s billion",
      },
    ],
  },
  {
    name: "grossMargin",
    title: grossMargin && grossMargin.title,
    totalScore: 7,
    question: grossMargin && grossMargin.question,
    options: [
      {key: "5", value: "90% or higher"},
      {key: "4", value: "70-89 %"},
      {key: "3", value: "50-69%"},
      {key: "2", value: "Less than 50%"},
    ],
  },
  {
    name: "marketFit",
    title: marketFit && marketFit.title,
    totalScore: 14,
    question: marketFit && marketFit.question,
    options: [
      {
        key: "5",
        value: "Accelerating revenues and enthusiastic customers",
      },
      {key: "4", value: "Initial revenues and enthusiastic customers"},
      {key: "3", value: "Little to no revenue but enthusiastic beta testers"},
      {
        key: "2",
        value: "A few beta testers with limited enthusiasm",
      },
      {key: "1", value: "No proof of product market fit"},
    ],
  },
  {
    name: "organizationalScalability",
    title: organizationalScalability && organizationalScalability.title,
    totalScore: 5,
    question: organizationalScalability && organizationalScalability.question,
    options: [
      {
        key: "5",
        value: "They know how and have the resources",
      },
      {
        key: "4",
        value: "They know how but don’t have the resources",
      },
      {
        key: "3",
        value:
          "They think they know how but haven’t done it before, and don’t have the resources",
      },
      {
        key: "2",
        value: "They don’t know how or have the resources",
      },
    ],
  },
  {
    name: "operationalScalability",
    title: operationalScalability && operationalScalability.title,
    totalScore: 5,
    question: operationalScalability && operationalScalability.question,
    options: [
      {
        key: "5",
        value: "Your company has the expertise and resources to scale",
      },
      {
        key: "4",
        value: "Your company has the expertise, but not the resources to scale",
      },
      {
        key: "3",
        value: "Your company might have the expertise to scale, but not the resources",
      },
      {
        key: "2",
        value: "Your company lacks the expertise and resources to scale",
      },
    ],
  },
];

export const ALL = [...FIRST, ...SECOND];
