import MODEL from "../../common/business-model";

const options = [
  {key: "10", value: 10},
  {key: "9", value: 9},
  {key: "8", value: 8},
  {key: "7", value: 7},
  {key: "6", value: 6},
  {key: "5", value: 5},
  {key: "4", value: 4},
  {key: "3", value: 3},
  {key: "2", value: 2},
  {key: "1", value: 1},
];

export default {
  ...MODEL,
  adminComment: {
    name: "adminComment",
    title: "Comments",
  },
  questionnaireList: [
    {
      title: "Essential Element",
      list: [
        {
          name: "essentialElement1",
          title: "Winner Take Most",
          options: options,
        },
        {
          name: "essentialElement1Interpretation",
          title: "Interpretation",
        },
        {
          name: "essentialElement2",
          title: "Scalable Distribution",
          options: options,
        },
        {
          name: "essentialElement2Interpretation",
          title: "Interpretation",
        },
      ],
    },
    {
      title: "Necessary Element",
      list: [
        {
          name: "marketSize",
          title: "Market Size (Growth Enhancer)",
          options: options,
        },
        {
          name: "marketSizeInterpretation",
          title: "Interpretation",
        },
        {
          name: "grossMargin",
          title: "Gross Margin (Growth Enhancer)",
          options: options,
        },
        {
          name: "grossMarginInterpretation",
          title: "Interpretation",
        },
        {
          name: "marketFit",
          title: "(Lack of) Product/Market Fit (Growth Limiter)",
          options: options,
        },
        {
          name: "marketFitInterpretation",
          title: "Interpretation",
        },
        {
          name: "organizationalScalability",
          title: "Organizational Scalability (Growth Limiter)",
          options: options,
        },
        {
          name: "organizationalScalabilityInterpretation",
          title: "Interpretation",
        },
        {
          name: "operationalScalability",
          title: "Operational Scalability (Growth Limiter)",
          options: options,
        },
        {
          name: "operationalScalabilityInterpretation",
          title: "Interpretation",
        },
      ],
    },
  ],
};
