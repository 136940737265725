import React, {memo, useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {getFundingTypeStatistics} from "../../../api/admin";
import {getData} from "./colors";

const FundingType = (submissionType) => {
  const [fundingType, setFundingType] = useState([]);
  useEffect(() => {
    getFundingTypeStatistics(submissionType).then((data) => {
      setFundingType(data);
    });
  }, [submissionType]);

  const fundingTypeData = (fundingType && fundingType.data) || [];
  const labels = fundingTypeData.map((c) => c._id) || [];
  const data = fundingTypeData.map((c) => c.count) || [];

  return <Pie data={getData({labels, data})} type="pie" />;
};

export default memo(FundingType);
