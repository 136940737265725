import React, {useState} from "react";
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import {useFormik} from "formik";
import adminFormSchema from "./admin-form-schema";
import MODEL from "./admin-business-model";
import {CountriesList, PrimaryButton} from "../../widgets";
import DialogAlert from "../../widgets/alert/DialogAlert";
import {adminAddEditCompany} from "../../../api/admin";
import {useHistory} from "react-router-dom";

const TableForm = (props) => {
  const history = useHistory();
  const editCompany = (props && props.companyInfo) || false;
  const [openDialog, setOpenDialog] = useState(false);

  const handleSubmit = async (formData) => {
    const requestData = {...formData, submissionType: "admin"};
    adminAddEditCompany({
      requestData,
      updateID: editCompany ? editCompany._id : false,
      parsed: false,
    })
      .then((response) => {
        // something wrong
        if (response === undefined || response === null || !response) {
          setOpenDialog(true);
          return;
        }
        history.push("/dashboard/all-submissions");
      })
      .catch((error) => {
        console.log(error);
        setOpenDialog(true);
      });
  };

  const formik = useFormik({
    initialValues: {
      // firstName: "Admin",
      // lastName: "Haa",
      // email: "test@test.com",
      // companyName: "vasdfas",
      // website: "",
      // companySize: "1-9",
      // revenue: "Pre-Product",
      // fundingType: "Seed",
      // category: "B2C",
      // business: "Marketplace",
      // essentialElement1: "10",
      // essentialElement2: "8",
      // marketSize: "9",
      // grossMargin: "8",
      // marketFit: "5",
      // organizationalScalability: "10",
      // operationalScalability: "9",
      // country: "",
      // essentialElement1Interpretation: "",
      // essentialElement2Interpretation: "",
      // marketSizeInterpretation: "",
      // grossMarginInterpretation: "",
      // marketFitInterpretation: "",
      // organizationalScalabilityInterpretation: "",
      // operationalScalabilityInterpretation: "",
      // adminComment: "admin Comment",
      firstName: editCompany.firstName || "",
      lastName: editCompany.lastName || "",
      email: editCompany.email || "",
      companyName: editCompany.companyName || "",
      website: editCompany.website || "",
      companySize: editCompany.companySize || "",
      revenue: editCompany.revenue || "",
      fundingType: editCompany.fundingType || "",
      category: editCompany.category || "",
      business: editCompany.business || "",
      essentialElement1:
        (editCompany.pointsDistribution &&
          editCompany.pointsDistribution.essentialElement1) ||
        "",
      essentialElement2:
        (editCompany.pointsDistribution &&
          editCompany.pointsDistribution.essentialElement2) ||
        "",
      marketSize:
        (editCompany.pointsDistribution && editCompany.pointsDistribution.marketSize) ||
        "",
      grossMargin:
        (editCompany.pointsDistribution && editCompany.pointsDistribution.grossMargin) ||
        "",
      marketFit:
        (editCompany.pointsDistribution && editCompany.pointsDistribution.marketFit) ||
        "",
      organizationalScalability:
        (editCompany.pointsDistribution &&
          editCompany.pointsDistribution.organizationalScalability) ||
        "",
      operationalScalability:
        (editCompany.pointsDistribution &&
          editCompany.pointsDistribution.operationalScalability) ||
        "",
      country: editCompany.country || "",
      essentialElement1Interpretation:
        (editCompany.interpretation && editCompany.interpretation.essentialElement1) ||
        "",
      essentialElement2Interpretation:
        (editCompany.interpretation && editCompany.interpretation.essentialElement2) ||
        "",
      marketSizeInterpretation:
        (editCompany.interpretation && editCompany.interpretation.marketSize) || "",
      grossMarginInterpretation:
        (editCompany.interpretation && editCompany.interpretation.grossMargin) || "",
      marketFitInterpretation:
        (editCompany.interpretation && editCompany.interpretation.marketFit) || "",
      organizationalScalabilityInterpretation:
        (editCompany.interpretation &&
          editCompany.interpretation.organizationalScalability) ||
        "",
      operationalScalabilityInterpretation:
        (editCompany.interpretation &&
          editCompany.interpretation.operationalScalability) ||
        "",
      adminComment: editCompany.adminComment || "",
    },
    validationSchema: adminFormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form id="blitzscalability-admin-form" onSubmit={formik.handleSubmit}>
        <p style={{fontSize: "30px", fontWeight: "900", textAlign: "center"}}>
          {MODEL.sectionHeader.header1.title}
        </p>

        {getTextField(1, MODEL.firstName, formik)}
        {getTextField(2, MODEL.lastName, formik)}
        {getTextField(3, MODEL.email, formik)}
        {getTextField(4, MODEL.companyName, formik)}
        {getTextField(5, MODEL.website, formik)}
        {getDropdownLayout(6, MODEL.country, formik, <CountriesList />)}
        {getDropdownLayout(7, MODEL.businessModel.items[0], formik)}
        {getDropdownLayout(8, MODEL.businessModel.items[1], formik)}
        {getDropdownLayout(9, MODEL.companyStage.items[0], formik)}
        {getDropdownLayout(10, MODEL.companyStage.items[1], formik)}
        {getDropdownLayout(11, MODEL.fundingType, formik)}

        <div>
          <p style={{fontSize: "30px", fontWeight: "900", textAlign: "center"}}>
            {MODEL.sectionHeader.header2.title}
          </p>
          {MODEL.questionnaireList.map((model, idx) => (
            <div key={idx}>
              <h2 style={{margin: "20px auto"}}>{model.title}</h2>
              <Grid container spacing={3}>
                {model.list.map((l, index) => (
                  <Grid key={index} item xs={6}>
                    {l.name.indexOf("Interpretation") === -1
                      ? getDropdownLayout(index + 12, l, formik)
                      : getMultiLineTextField(index + 12, l, formik)}
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </div>

        {getMultiLineTextField(12 + 14 + 1, MODEL.adminComment, formik)}
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "horizontal",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryButton type="submit" title="SUBMIT" />
        </div>
      </form>
      {openDialog ? (
        <DialogAlert
          title="Error"
          content="Something is wrong, please try again!"
          button="Ok"
          openDialog={openDialog}
        />
      ) : null}
    </div>
  );
};

const getTextField = (num, model, formik) => {
  return (
    <Grid key={num} container spacing={6}>
      <Grid item xs={6}>
        <TextField
          style={{width: 450}}
          fullWidth
          name={model.name}
          label={model.title}
          value={formik.values[model.name]}
          onChange={formik.handleChange}
          error={formik.touched[model.name] && Boolean(formik.errors[model.name])}
          helperText={formik.touched[model.name] && formik.errors[model.name]}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

const getMultiLineTextField = (num, model, formik) => {
  return (
    <Grid key={num} container spacing={6}>
      <Grid item xs={6}>
        <TextField
          style={{width: 450}}
          fullWidth
          multiline
          rows={4}
          name={model.name}
          label={model.title}
          value={formik.values[model.name]}
          onChange={formik.handleChange}
          error={formik.touched[model.name] && Boolean(formik.errors[model.name])}
          helperText={formik.touched[model.name] && formik.errors[model.name]}
          variant="outlined"
          inputProps={{maxLength: 500}}
        />
      </Grid>
    </Grid>
  );
};

const getDropdownLayout = (num, model, formik, content) => {
  return (
    <Grid key={num} container spacing={6}>
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          error={formik.touched[model.name] && Boolean(formik.errors[model.name])}
          style={{width: 450}}
        >
          <InputLabel htmlFor={model.name}>
            {model.title ? model.title : model.subtitle}
          </InputLabel>
          <Select
            native
            label={model.title ? model.title : model.subtitle}
            value={formik.values[model.name]}
            onChange={formik.handleChange}
            inputProps={{
              name: `${model.name}`,
              id: `${model.name}`,
            }}
          >
            <option value="" disabled="" defaultValue="">
              {}
            </option>
            {content
              ? content
              : model.options.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
          </Select>
          <FormHelperText>
            {formik.touched[model.name] && formik.errors[model.name]}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default React.memo(TableForm);
