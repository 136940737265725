import React, {useEffect, useState} from "react";
import StatisticsTabs from "../../components/admin/statistics/StatisticsTabs";
import Category from "../../components/admin/statistics/Category";
import Country from "../../components/admin/statistics/Country";
import TypeOfBusiness from "../../components/admin/statistics/TypeOfBusiness";
import NumOfEmployees from "../../components/admin/statistics/NumOfEmployees";
import RevenueStage from "../../components/admin/statistics/RevenueStage";
import FundingType from "../../components/admin/statistics/FundingType";
import TotalScore from "../../components/admin/statistics/TotalScore";
import {FormControl, Grid, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const submissionFilter = [
  {value: "client", label: "Client Submission"},
  {value: "admin", label: "Admin Submission"},
];

export default function StatisticsPage() {
  const classes = useStyles();
  const [submissionType, setSubmissionType] = useState("");
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    setSubmissionType(filter);
  }, [filter]);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={2}>
          <FormControl className={classes.formControl}>
            <Select
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              displayEmpty
              className={classes.formControl}
              inputProps={{"aria-label": "Without label"}}
            >
              <MenuItem key={0} value="all">
                All
              </MenuItem>
              {submissionFilter.map((filter, index) => (
                <MenuItem key={index + 1} value={filter.value}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <StatisticsTabs
        category={<Category submissionType={submissionType} />}
        country={<Country submissionType={submissionType} />}
        typeOfBusiness={<TypeOfBusiness submissionType={submissionType} />}
        numOfEmployees={<NumOfEmployees submissionType={submissionType} />}
        revenueStage={<RevenueStage submissionType={submissionType} />}
        fundingType={<FundingType submissionType={submissionType} />}
        totalScore={<TotalScore submissionType={submissionType} />}
      />
    </>
  );
}
