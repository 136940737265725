import MODEL from "../../common/business-model";

export default {
  ...MODEL,
  clientComment: {
    name: "clientComment",
    title: "Comments",
  },
  submit: {
    title: "Click submit to see your results",
    subTitle1: "By submitting this form you are agreeing with our ",
    subTitle2: "terms & conditions",
    subTitle3:
      ". Your company name and data will be kept private and confidential by Blitzscaling Academy at all times.",
  },
  questionnaireList: [
    {
      name: "essentialElement1",
      multiQuestions: true,
      title: "Essential Element 1: Winner Take Most",
      tooltips:
        "Network Effects and Land Grab often display positive synergies. Your Combined Winner Take Most score will be at least as high as the greater of Score A and B (eg, if a customer stays with you forever, it doesn’t really matter why!).",
      items: [
        {
          name: "networkEffects",
          subtitle: "Network Effects (Growth Enhancer)",
          description:
            "A network effect exists when a product’s value to a user increases as the number of users of the product grows. There are many different forms of network effects, some stronger and some weaker than others.",
          question:
            "On a scale of 1-5, evaluate the extent to which your business benefits from network effects. Use the following statements as a guide*:",
          descList: [
            {
              key: "advantage",
              value:
                "Achieving critical scale provides you an enduring competitive advantage (5)",
              tooltips:
                "Practically speaking, this occurs when the value to the individual user provided by the network effect is so high that a competitor could clone your product exactly and offer it for free, yet still get no traction because its product value would be dwarfed by your network value.",
            },
            {
              key: "temporaryAdvantage",
              value:
                "Achieving critical scale provides you a competitive advantage but that advantage is non-dispositive or temporary (3)",
              tooltips:
                "If the network’s value to the individual user is small relative to the product value, the network effect will not trigger enduring market leadership. Alternatively, if the network value is large, but it is either temporary, or easy for a competitor to achieve as well (e.g. Uber and Lyft), the network effect will not trigger enduring market leadership.",
            },
            {
              key: "noAdvantage",
              value:
                "Achieving critical scale does not provide you a competitive advantage (1)",
            },
          ],
          optionsDropDown: true,
          options: [
            {key: "1", value: 1},
            {key: "2", value: 2},
            {key: "3", value: 3},
            {key: "4", value: 4},
            {key: "5", value: 5},
          ],
        },
        {
          name: "landGrab",
          subtitle: "Land Grab (Growth Enhancer)",
          description:
            "The key question you need to answer is whether you’re in a market where you are first with a new technology " +
            "and there is limited competition or you’re in an established market with many competitors. " +
            "If you’re in a relatively competitive and new market, a land grab strategy makes sense.",
          question:
            "On a scale of 1-5, to what extent does your business involve a land grab? Use the following statements as a guide*:",
          descList: [
            {
              key: "forever",
              value: "Once a customer is won, it stays with you forever (5)",
            },
            {
              key: "severalYears",
              value:
                "Once a customer is won, you will likely retain it for several years (e.g. Annual retention > 80%) (3)",
            },
            {
              key: "takeAway",
              value:
                "It is easy for you and your competitors to take the others’ customers away (e.g. Annual retention < 80%) (1)",
            },
          ],
          optionsDropDown: true,
          options: [
            {key: "1", value: 1},
            {key: "2", value: 2},
            {key: "3", value: 3},
            {key: "4", value: 4},
            {key: "5", value: 5},
          ],
        },
      ],
    },
    {
      name: "essentialElement2",
      multiQuestions: true,
      title: "Essential Element 2: Scalable Distribution",
      tooltips:
        " Distribution and Virality often display positive synergies. " +
        "Your Combined Customer Acquisition score will be at least as high as the greater of Score A and B" +
        " (eg, if you can get a new customer easily and efficiently, it doesn’t really matter how!).",
      items: [
        {
          name: "distribution",
          subtitle: "Distribution (Growth Enhancer)",
          description:
            "How scalable is your distribution?  Do your customers refer other customers?  Are you able to tap into existing distribution networks to take your product to market?",
          question: "Rate the strength of your distribution on a scale of 1-5*:",
          descList: [
            {
              key: "nearly",
              value:
                "You have formed exclusive relationships (including advertising) with distributors that will allow you to reach nearly the entire available market (5)",
            },
            {
              key: "significant",
              value:
                "You have formed relationships (including advertising) with distributors that will allow you to reach a significant percentage (>10%) of the entire available market (3)",
            },
            {
              key: "all",
              value:
                "You have to acquire substantially all of your customers directly (1)",
            },
          ],
          optionsDropDown: true,
          options: [
            {key: "1", value: 1},
            {key: "2", value: 2},
            {key: "3", value: 3},
            {key: "4", value: 4},
            {key: "5", value: 5},
          ],
        },
        {
          name: "virality",
          subtitle: "Virality (Growth Enhancer)",
          description:
            "The key metric of virality is the viral coefficient, or “K” factor. " +
            "This metric represents the number of additional customers that each existing customer will refer (in a reasonable period of time). " +
            "K = 2 means that each customer refers two more, which leads quickly to exponential growth.",
          question: "Rate the virality of your product on a scale of 1-5*:",
          descList: [
            {
              key: "exponential",
              value:
                "Your existing customers are highly likely to refer others (K > 1), driving exponential growth (5)",
            },
            {
              key: "additional",
              value:
                "Your existing customers often refer others (0.5 < K < 1), driving some additional growth (3)",
            },
            {
              key: "refer",
              value: "Your existing customers seldom refer others (K < 0.5) (1)",
            },
          ],
          optionsDropDown: true,
          options: [
            {key: "1", value: 1},
            {key: "2", value: 2},
            {key: "3", value: 3},
            {key: "4", value: 4},
            {key: "5", value: 5},
          ],
        },
      ],
    },
    {
      name: "marketSize",
      title: "Necessary Element 1: Market Size (Growth Enhancer)",
      description:
        "Market size is critical to the success of your business, particularly if you plan to blitzscale.",
      question: "How large is the total addressable market for your business?*",
      options: [
        {key: "5", value: "Essentially unbounded"},
        {key: "4", value: "Very large (measured in the $10s of billions)"},
        {key: "3", value: "Large (measured in the $1s of billions)"},
        {key: "2", value: "Will be in the $1s billions in a few years"},
        {
          key: "1",
          value: "Will always be less than a $1s billion",
        },
      ],
    },
    {
      name: "grossMargin",
      title: "Necessary Element 2: Gross Margin (Growth Enhancer)",
      description:
        "Gross margin represents a business's revenue after accounting only for the cost of producing goods or services for sale. " +
        "Therefore, a low margin often means your business won't have enough income left over " +
        "to cover fixed expenses or the other costs of running the business, much less to finance rapid scaling.",
      question: "What is the gross margin of your business?*",
      options: [
        {key: "5", value: "90% or higher"},
        {key: "4", value: "70-89 %"},
        {key: "3", value: "50-69%"},
        {key: "2", value: "Less than 50%"},
      ],
    },
    {
      name: "marketFit",
      title: "Necessary Element 3: (Lack of) Product/Market Fit (Growth Limiter)",
      description:
        "Product-market fit means being in a good market with a product that can satisfy that market. Startups require a solid foundation of product-market fit before progressing up the pyramid of scaling the business.",
      question:
        "How would you describe the current level of product market fit your startup has obtained?*",
      options: [
        {
          key: "5",
          value: "Accelerating revenues and enthusiastic customers",
        },
        {key: "4", value: "Initial revenues and enthusiastic customers"},
        {key: "3", value: "Little to no revenue but enthusiastic beta testers"},
        {
          key: "2",
          value: "A few beta testers with limited enthusiasm",
        },
        {key: "1", value: "No proof of product market fit"},
      ],
    },
    {
      name: "organizationalScalability",
      title: "Necessary Element 4: Organizational Scalability (Growth Limiter)",
      description:
        "As your business starts to scale, the rate of hiring will accelerate quickly and you will experience growing pains.",
      question:
        "How well prepared are the CEO and leadership team to scale and manage your organization through fast growth?*",
      options: [
        {
          key: "5",
          value: "They know how and have the resources",
        },
        {
          key: "4",
          value: "They know how but don’t have the resources",
        },
        {
          key: "3",
          value:
            "They think they know how but haven’t done it before, and don’t have the resources",
        },
        {
          key: "2",
          value: "They don’t know how or have the resources",
        },
      ],
    },
    {
      name: "operationalScalability",
      title: "Necessary Element 5: Operational Scalability  (Growth Limiter)",
      description:
        "When your business starts to scale, the demands on your technology platform and other infrastructure will increase dramatically and present challenges.",
      question:
        "How well prepared are the CEO and leadership team to manage the process of scaling the company’s technology and infrastructure?*",
      options: [
        {
          key: "5",
          value: "Your company has the expertise and resources to scale",
        },
        {
          key: "4",
          value: "Your company has the expertise, but not the resources to scale",
        },
        {
          key: "3",
          value: "Your company might have the expertise to scale, but not the resources",
        },
        {
          key: "2",
          value: "Your company lacks the expertise and resources to scale",
        },
      ],
    },
  ],
};
