import React from "react";

const CanvasPdfDescription = ({roundedProcessedValues}) => {
  return (
    <div
      style={{
        width: "80%",
        margin: "100px auto 0 auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 722 250"
        style={{overflow: "initial"}}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="map">
            <text className="canvas-pdf-des-title" transform="translate(35 10)">
              ESSENTIAL ELEMENTS
            </text>
            <circle className="cls-15" cx="30" cy="55.4" r="14.4" />
            <text className="cls-14" transform="translate(65 62.32)">
              1. Winner Take Most:{" "}
              {roundedProcessedValues && roundedProcessedValues.essentialElement1}
              /29
            </text>
            <text className="cls-14" transform="translate(65 92.32)">
              • Network Effects
            </text>
            <text className="cls-14" transform="translate(65 122.32)">
              • Land Grab
            </text>
            <circle className="cls-16" cx="30" cy="163.73" r="14.4" />
            <text className="cls-14" transform="translate(65 170.65)">
              2. Scalable Distribution:{" "}
              {roundedProcessedValues && roundedProcessedValues.essentialElement2}
              /29
            </text>
            <text className="cls-14" transform="translate(65 200.65)">
              • Distribution
            </text>
            <text className="cls-14" transform="translate(65 230.65)">
              • Virality
            </text>

            <text className="canvas-pdf-des-title" transform="translate(440 10)">
              NECESSARY ELEMENTS
            </text>
            <circle className="cls-17" cx="440" cy="55.4" r="14.4" />
            <text className="cls-14" transform="translate(475 62.32)">
              1. Market Size:{" "}
              {roundedProcessedValues && roundedProcessedValues.marketSize}
              /11
            </text>
            <circle className="cls-18" cx="440" cy="103.73" r="14.4" />
            <text className="cls-14" transform="translate(475 110.65)">
              2. Gross Margin:{" "}
              {roundedProcessedValues && roundedProcessedValues.grossMargin}
              /7
            </text>
            <circle className="cls-21" cx="440" cy="152.05" r="14.4" />
            <text className="cls-14" transform="translate(475 158.98)">
              3. (Lack of) Product Market Fit:{" "}
              {roundedProcessedValues && roundedProcessedValues.marketFit}
              /14
            </text>
            <circle className="cls-20" cx="440" cy="200.38" r="14.4" />
            <text className="cls-14" transform="translate(475 207.31)">
              4. Organizational Scalability:{" "}
              {roundedProcessedValues && roundedProcessedValues.organizationalScalability}
              /5
            </text>
            <circle className="cls-19" cx="440" cy="248.71" r="14.4" />
            <text className="cls-14" transform="translate(475 255.64)">
              5. Operational Scalability:{" "}
              {roundedProcessedValues && roundedProcessedValues.operationalScalability}
              /5
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CanvasPdfDescription;
