import React from "react";
import logo from "../../../assets/images/BlitzscalingAcademyLogo.png";
import dlogo from "../../../assets/images/BALogoWhite.png";

const Logo = ({width, dark}) => {
  return (
    <a
      rel="noopener noreferrer"
      href="https://www.blitzscalingacademy.com/"
      target="_blank"
    >
      <img
        style={{height: "auto", width: width || "250px"}}
        src={dark ? dlogo : logo}
        alt=""
      />
    </a>
  );
};

export default Logo;
