import * as Yup from "yup";
import formSchema from "../../common/form-validation";

const clientFormSchema = Yup.object().shape({
  ...formSchema,
  companyRole: Yup.string().required("This is a required field"),
  support: Yup.string().required("This is a required field"),
  distribution: Yup.number().required("This is a required field"),
  virality: Yup.number().required("This is a required field"),
  networkEffects: Yup.string().required("This is a required field"),
  landGrab: Yup.string().required("This is a required field"),
  clientComment: Yup.string(),
});

export default clientFormSchema;
