import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {Backdrop, Fade, Grid, Modal} from "@material-ui/core";
import {PrimaryButton} from "../../widgets";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CompanyInfoModal = ({company, open, handleClose}) => {
  const classes = useStyles();

  return (
    <div style={{width: "60%"}}>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <Fade in={open}>
          <div id="company-modal" className={classes.root}>
            <form autoComplete="off">
              <h2 id="simple-modal-title">{company && company.companyName}</h2>
              <ModalTabs
                details={companyInfo(company)}
                evaluation={evaluation(company)}
                comments={commentsTab(company)}
              />
            </form>
            <PrimaryButton type="button" title="Close" onClick={handleClose} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const commentsTab = (company) => (
  <Grid container>
    <Grid item xs={12}>
      <h2>{company.submissionType === "admin" ? "Admin Comments" : "User Comments"}</h2>
      <Grid item>
        {company && company.submissionType === "admin"
          ? company.adminComment
          : company.clientComment}
      </Grid>
    </Grid>
  </Grid>
);

const companyInfo = (company) => (
  <Grid container>
    <Grid item xs={12}>
      <h2>Company info</h2>
      <p>First Name: {company && company.firstName}</p>
      <p>Last name: {company && company.lastName}</p>
      <p>Email: {company && company.email}</p>
      <p>Company Name: {company && company.companyName}</p>
      <p>Website URL: {company && company.website}</p>
      <p>Country: {company && company.country}</p>
      <p>Your role in the company: {company && company.companyRole}</p>
      <p>Want Scaling Support: {company && company.support}</p>
      <p>Business Category: {company && company.category}</p>
      <p>Business Type: {company && company.business}</p>
      <p>Number of Employees: {company && company.companySize}</p>
      <p>Revenue Stage: {company && company.revenue}</p>
    </Grid>
  </Grid>
);

const evaluation = (company) => (
  <Grid container>
    <Grid item xs={12}>
      <h2>Growth Factor Evaluation</h2>
      <p>
        {`Winner Take Most: ${
          company && company.processedValues && company.processedValues.essentialElement1
        } (${
          company &&
          company.pointsDistribution &&
          company.pointsDistribution.essentialElement1
        })`}
      </p>
      <p>
        {`Scalable Distribution: ${
          company && company.processedValues && company.processedValues.essentialElement2
        } (${
          company &&
          company.pointsDistribution &&
          company.pointsDistribution.essentialElement2
        })`}
      </p>
      <p>
        {`Market Size: ${
          company && company.processedValues && company.processedValues.marketSize
        }
       (${
         company && company.pointsDistribution && company.pointsDistribution.marketSize
       })`}
      </p>
      <p>
        {`Gross Margin: ${
          company && company.processedValues && company.processedValues.grossMargin
        }
       (${
         company && company.pointsDistribution && company.pointsDistribution.grossMargin
       })`}
      </p>
      <p>
        {`Product / Market Fit: ${
          company && company.processedValues && company.processedValues.marketFit
        }
       (${
         company && company.pointsDistribution && company.pointsDistribution.marketFit
       })`}
      </p>
      <p>
        {`Organizational Scalability: ${
          company &&
          company.processedValues &&
          company.processedValues.organizationalScalability
        }
       (${
         company &&
         company.pointsDistribution &&
         company.pointsDistribution.organizationalScalability
       })`}
      </p>
      <p>
        {`Operational Scalability: ${
          company &&
          company.processedValues &&
          company.processedValues.operationalScalability
        }
       (${
         company &&
         company.pointsDistribution &&
         company.pointsDistribution.operationalScalability
       })`}
      </p>
      <p>{`Total score: ${company && company.blitzScalabilityScore}`}</p>
    </Grid>
  </Grid>
);

const ModalTabs = ({details, evaluation, comments}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Company Information" />
        <Tab label="Growth Factor Evaluation" />
        <Tab label="Comments" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div>{details}</div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>{evaluation}</div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>{comments}</div>
      </TabPanel>
    </div>
  );
};

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default CompanyInfoModal;
