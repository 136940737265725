import React from "react";
import ClientNavbar from "../../../components/widgets/layout/ClientNavbar";
import Theme from "../../../utils/theme";
import {ThemeProvider} from "styled-components";

const PageWrapper = ({children, dark, ...props}) => {
  return (
    <div {...props}>
      <ThemeProvider theme={Theme}>
        <ClientNavbar dark={dark}>{children}</ClientNavbar>
      </ThemeProvider>
    </div>
  );
};

export default PageWrapper;
