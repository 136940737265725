import React from "react";
import Typography from "@material-ui/core/Typography";

const Footer = ({darkTheme}) => {
  return (
    <Typography
      variant="body2"
      align="center"
      style={{
        marginTop: "50px",
        marginBottom: "30px",
        color: `${darkTheme ? "#fff" : "#000"}`,
      }}
    >
      {`© ${new Date().getFullYear()} Global Scaling Academy LLC`}
    </Typography>
  );
};

export default Footer;
