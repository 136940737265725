import React from "react";
import {Link} from "react-router-dom";
import {Section, Title, Text, PrimaryButton} from "../components/widgets";
import PageWrapper from "../components/widgets/layout/PageWrapper";
import {Container} from "@material-ui/core";

const ThankYouPage = () => {
  return (
    <PageWrapper>
      <Section>
        <div className="pt-5" />
        <Container>
          <div className="text-center">
            <div>
              <Title variant="hero">Thank You</Title>
              <Text>
                Thank you for submitting the form.
                <br className="d-none d-md-block" />
                You should receive an email shortly
              </Text>
            </div>
            <div style={{marginTop: "30px"}}>
              <Link to="/">
                <a>
                  <PrimaryButton type="button" title="Go back to home" />
                </a>
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </PageWrapper>
  );
};

export default ThankYouPage;
