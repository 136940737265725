export const FORM_HEADER_DESCRIPTION_TITLE =
  "The Blitzscalability Grader is a business model evaluation tool intended to empower you to build a more scalable business.";
export const FORM_HEADER_DESCRIPTION1 = {
  title:
    "When you have completed this assessment, you will receive a report that can help you:",
  list: [
    "Understand the current scalability of your company’s business model",
    "Identify ways to increase the scalability of your business",
    "Compare your company’s scalability to other businesses",
    "Discuss scalability with team members, advisors and investors",
  ],
};
export const FORM_HEADER_DESCRIPTION2 = {
  title:
    "Use the grader multiple times to model different scenarios and see how changing your answers to the questions affects your score. Keep in mind that:",
  list: [
    "Your goal should not be to achieve a higher score, but to gain insight into how to build a more scalable business",
    "Your score should be interpreted relative to your industry and competitors",
    "Not all companies will score highly, but that does not mean they can’t be attractive businesses",
    "Blitzscaling is a strategy that should only be applied responsibly in a limited set of circumstances when certain conditions are present",
  ],
};

export const NOTE =
  "Note: If either A or B is ＜ 3，the final score takes the higher one of A and B up to the maximum of 5; If A and B are both ＞＝3, the final score takes the higher one of A and B +1 up to the maximum of 5, because it is likely that the synergies will push your score up one increment.";

export const RESULT_CANVAS_TITLE = "The Blitzscalability Report";
export const RESULT_CANVAS_DROPDOWN_TITLE =
  "View the professional reports of well-known companies";
export const RESULT_RADAR_DROPDOWN_TITLE = "Compare your score to well-known companies";

export const ADMIN_ADD_WELL_KNOWN_COMPANIES_TITLE = "Add Well known companies";
export const ADMIN_EDIT_WELL_KNOWN_COMPANIES_TITLE = "Edit Company";
