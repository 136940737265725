import React, {memo} from "react";
import {Bar} from "react-chartjs-2";
import {ALL} from "../../common/result-model";
import {getRandomColor} from "../../common/helper";

const CompareCompaniesBarChart = ({processedValues, companyName, selectedCompanies}) => {
  const getData = (data) => {
    delete data.organizationalStage;
    return {
      names: (data && Object.keys(data)) || [],
      values: (data && Object.values(data)) || [],
    };
  };

  const selectedProcessedValuesData = getData(processedValues);

  const getProcessedValuesData = (data) => {
    const obj = [];
    selectedProcessedValuesData.names.forEach((name) => {
      obj[name] = data && data[name];
    });
    return getData(obj);
  };

  const getDatasets = (selectedCompanies) => {
    const data = [
      {
        label: companyName,
        backgroundColor: "rgba(50,121,168)",
        data: selectedProcessedValuesData.values,
        dataName: selectedProcessedValuesData.names,
      },
    ];
    if (selectedCompanies && selectedCompanies.length > 0) {
      selectedCompanies.forEach((company) => {
        const processedValuesData = getProcessedValuesData(
          company && company.processedValues
        );
        data.push({
          label: company.companyName,
          backgroundColor: getRandomColor(),
          data: processedValuesData.values,
          dataName: processedValuesData.names,
        });
      });
    }
    return data;
  };

  const data = {
    labels: selectedProcessedValuesData.names,
    datasets: getDatasets(selectedCompanies),
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex || 0;
            let label = context.dataset.label || "";
            let data = context.dataset.data || [];
            let dataName = context.dataset.dataName || [];

            if (label) {
              label += `: ${data[dataIndex]}`;
            }

            if (dataName[dataIndex]) {
              const item =
                ALL.find((m) => {
                  return m.name === dataName[dataIndex];
                }) || {};
              const totalScore = item.totalScore || "";
              label += totalScore ? `/${totalScore}` : "";
            }
            return label;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} type="bar" />;
};

export default memo(CompareCompaniesBarChart);
