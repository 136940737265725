import React from "react";
import * as Yup from "yup";

const formSchema = {
  firstName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),
  lastName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),
  email: Yup.string().email("Invalid email").required("This is a required field"),
  companyName: Yup.string()
    .min(2, "Company name must be at least 2 characters long")
    .max(50, "Company name cannot exceed 50 characters")
    .required("This is a required field"),
  companySize: Yup.string().required("This is a required field"),
  revenue: Yup.string().required("This is a required field"),
  category: Yup.string().required("This is a required field"),
  business: Yup.string().required("This is a required field"),
  marketSize: Yup.string().required("This is a required field"),
  grossMargin: Yup.string().required("This is a required field"),
  marketFit: Yup.string().required("This is a required field"),
  organizationalScalability: Yup.string().required("This is a required field"),
  operationalScalability: Yup.string().required("This is a required field"),
  country: Yup.string().required("This is a required field"),
  fundingType: Yup.string().required("This is a required field"),
};

export default formSchema;
