import React from "react";
import {Route, Router} from "react-router-dom";
import AdminRoutes from "./utils/routes/AdminRoutes";
import ClientRoutes from "./utils/routes/ClientRoutes";
import history from "./utils/history";
import {useAuth0} from "./utils/auth/auth0-provider-with-history";
import {Loading} from "./components/widgets";
import HomePage from "./pages/HomePage";

import "./App.css";

function App() {
  const {isAuthenticated, loading} = useAuth0();
  if (loading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
      <Route exact path="/" component={HomePage} />
      <AdminRoutes />
      <ClientRoutes />
    </Router>
  );
}

export default App;
