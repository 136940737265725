import React, {memo, useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {getNumOfEmployeesStatistics} from "../../../api/admin";
import {getData} from "./colors";

const NumOfEmployees = (submissionType) => {
  const [numOfEmployees, setNumOfEmployees] = useState([]);
  useEffect(() => {
    getNumOfEmployeesStatistics(submissionType).then((data) => {
      setNumOfEmployees(data);
    });
  }, [submissionType]);

  const numOfEmployeesData = (numOfEmployees && numOfEmployees.data) || [];
  const labels = numOfEmployeesData.map((c) => c._id) || [];
  const data = numOfEmployeesData.map((c) => c.count) || [];

  return <Pie data={getData({labels, data})} type="pie" />;
};

export default memo(NumOfEmployees);
