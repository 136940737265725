import React from "react";
import {Container} from "@material-ui/core";
import PageWrapper from "../../../components/widgets/layout/PageWrapper";

function RenderForm() {
  return (
    <PageWrapper>
      <Container maxWidth="md" style={{marginTop: "5em"}}>
        <div className={"title-container"} style={{marginTop: "150px"}}>
          <h1>Terms of Use</h1>
        </div>
        <div class="contentContainer">
          <p>
            Welcome to the Global Scaling Academy Website and thanks for visiting. We hope
            “You” enjoy “Your” experience.
          </p>
          <p>
            These Terms of Use (“Terms”) are a legal contract between You and Global
            Scaling Academy ("Us" or "Our" or "We") and govern Your use of{" "}
            <a href="//www.globalscalingacademy.com/?hsLang=en-us">
              www.globalscalingacademy.com
            </a>
            , the services We offer through Our Website (“Services”) and Community at
            www.community.globalscalingacademy.com, and all of the text, data,
            information, software, graphics, photographs and other materials (all
            together, the “Materials”) that We and Our affiliates may make available to
            You (all of which are referred to in these Terms as this “Website”).
          </p>
          <p>
            Through this Website, Global Scaling Academy offers online education, live
            events, workshops, consulting, and specialized mentorship to the founders of
            startup companies, corporate entrepreneurs and innovators, and venture
            investors who are seeking to accelerate the scaling of businesses.
            <span>&nbsp; </span>Our methodology is based on the Blitzscaling book,
            co-authored by Reid Hoffman and Chris Yeh, which is used under license from
            Allied Talent, LLC.
          </p>
          <p>
            READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE.
            <span>&nbsp; </span>USING THIS WEBSITE INDICATES THAT YOU HAVE BOTH READ AND
            ACCEPT THESE TERMS.<span>&nbsp; </span>YOU CANNOT USE THIS WEBSITE IF YOU DO
            NOT ACCEPT THESE TERMS IN FULL.
          </p>
          <p>
            <strong>
              <span>CHANGES</span>
            </strong>
            .
          </p>
          <p>
            We may alter the Materials and Services We offer You and/or choose to modify,
            suspend or discontinue this Website at any time and without notifying You.
            <span>&nbsp; </span>We may also change, update, add or remove provisions
            (collectively, “modifications”) of these Terms from time to time.
            <span>&nbsp; </span>Because everyone benefits from clarity, We promise to
            inform You of any modifications to these Terms by making them accessible via
            this Website.<span>&nbsp; </span>If You have registered for a Membership with
            Us, We will send modifications to You via the email address that You provided
            during Membership registration.
            <span>&nbsp; </span>To be sure We properly reach Your email inbox, We just ask
            that You let Us know if Your preferred email address changes at any time after
            Your Membership registration.
          </p>
          <p>
            If You object to any such modifications, Your sole recourse shall be to cease
            using this Website.
            <span>&nbsp; </span>Continued use of this Website following notice of any such
            modifications indicates You acknowledge and agree to be bound by the
            modifications. Also, please know that these Terms may be superseded by
            expressly-designated legal notices or terms located on particular pages of
            this Website that apply to specific functionality of the Website.
            <span>&nbsp; </span>These expressly-designated legal notices or terms are
            incorporated into these Terms and supersede the provision(s) of these Terms
            that are designated as being superseded.
          </p>
          <p>
            <strong>
              <span>GENERAL USE.</span>
            </strong>
          </p>
          <p>
            We invite You to use this Website for individual, consumer purposes
            ("Permitted Purposes").
            <span>&nbsp; </span>By using this Website, You promise that You are at least
            13 years of age.
            <span>&nbsp; </span>If You are not yet 18 years old, You must have the
            permission of an adult to use this Website and agree to its Terms, and that
            adult must be a parent or legal guardian who is willing be responsible for
            Your use of this Website.
          </p>
          <p>
            In these Terms We are granting You a limited, personal, non-exclusive and
            non-transferable license to use and to display the Materials; Your right to
            use the Materials is conditioned on Your compliance with these Terms.
            <span>&nbsp; </span>You have no other rights in this Website or any Materials
            and You may not modify, edit, copy, reproduce, create derivative works of,
            reverse engineer, alter, enhance or in any way exploit any of this Website or
            Materials in any manner.<span>&nbsp; </span>If You make copies of any of this
            Website while engaging in Permitted Purposes then We ask that You be sure to
            keep on the copies all of Our copyright and other proprietary notices as they
            appear on this Website.
            <span>&nbsp;&nbsp; </span>
          </p>
          <p>
            If You breach any of these Terms the above license will terminate
            automatically and You must immediately destroy any downloaded or printed
            Materials (and any copies thereof), and cease all use of the Services and
            Website.
          </p>
          <p>
            <strong>
              <span>USING THIS </span>
            </strong>
            <strong>
              <span>WEBSITE AND THE </span>
            </strong>
            <strong>
              <span>WEBSITE’S </span>
            </strong>
            <strong>
              <span>SERVICES.</span>
            </strong>
          </p>
          <p>
            We appreciate You visiting this Website and allow You to do just that – stop
            by and leisurely check it out without even registering with Us!
          </p>
          <p>
            However, in order to access certain password-restricted areas of this Website
            (such as joining our community and other User Submissions (defined below)) and
            to use certain Services and Materials offered on and through this Website, You
            must successfully register a Membership with Us.
          </p>
          <p>
            <strong>
              <span>MEMBERSHIP ACCOUNT REGISTRATION.</span>
            </strong>
          </p>
          <p>
            If You wish to create a Membership account, You must submit the following
            information through the account registration page on this Website:
            <span>&nbsp; </span>
          </p>
          <ul>
            <li>A working email address;</li>
            <li>First and last name;</li>
            <li>Preferred username and password.</li>
          </ul>
          <p>
            You may also have an opportunity to provide additional, optional information
            so that We can provide You a more customized experience when using this
            Website –but, We will leave that decision with You.
            <span>&nbsp; </span>Once You submit the required registration information, We
            alone will determine whether or not to approve Your application and proposed
            account.
            <span>&nbsp; </span>If approved, you will be permitted to log in to your
            account with your password.<span>&nbsp; </span>For so long as You use the
            account, You agree to provide true, accurate, current, and complete
            information which can be accomplished by logging into Your account and making
            relevant changes directly or contacting Us using the below contact information
            and We can make the changes for You.<span>&nbsp; </span>And, if You forget
            Your password – no worries as We will happily send a password update to Your
            provided email address.
          </p>
          <p>
            We may also provide You with the ability to register for an account using Your
            existing account and log-in credentials through Third-Party Sites (as defined
            below).<span>&nbsp; </span>By using such Third-Party Site account and/or
            credentials You are consenting to Our retention and use, and such Third-Party
            Site’s disclosure, of the account, credentials and other populated profile
            information that You submit.
          </p>
          <p>
            You are responsible for complying with these Terms when You access this
            Website, whether directly or through any account that You may setup through or
            on this Website.<span>&nbsp; </span>Because it is Your account, it is Your job
            to obtain and maintain all equipment and services needed for access to and use
            of this Website as well as paying related charges.
            <span>&nbsp; </span>It is also Your responsibility to maintain the
            confidentiality of Your password(s), including any password of a Third-Party
            Site that We may allow You to use to access this Website.
            <span>&nbsp; </span>Should You believe Your password or security for this
            Website has been breached in any way, You must immediately notify Us.
          </p>
          <p>
            <strong>
              <span>MEMBERSHIP.</span>
            </strong>
          </p>
          <p>
            By successfully completing a Membership application, registering for an
            account with Us and paying Your Membership fee, You become a “Member” with
            access to certain password-restricted areas of the Website and to use certain
            Services and Materials offered on and through the Website (a “Membership”),
            subject to those certain terms and conditions on the Membership page.
            <span>&nbsp; </span>
            Each Membership and the rights and privileges provided to a Member is personal
            and non-transferable.
          </p>
          <p>
            If you wish to link multiple Members to one Membership account, You must
            indicate the total number of Members participating at the time of account
            creation.<span>&nbsp; </span>The base Membership price is for one (1) Member,
            and there is an extra charge for additional Members.
            <span>&nbsp; </span>All services provided, including mentor hours and programs
            purchased, will be delivered via the Website and videoconference.
          </p>
          <p>
            Membership may include, but is not limited to, access to courses and content
            of Our educational program, discussion fora, events and community MasterMinds
            via the online platform, plus access to optional services that are available
            for additional purchase including curriculum, 1:1 mentorship, original and
            curated content, acceleration programs, advisory services, due diligence
            services and/ or the ability to set appointments through this Website with Our
            partners and network of mentors.
          </p>
          <p>
            You may encounter a pop-up or request for Your name and email address (and in
            some cases also Your company name, website URL, and/or Your phone number).
            <span>&nbsp; </span>Such information is requested in order to view or download
            content on the "Contact Us", and certain other pages, to complete an
            application for Membership consideration, or for completing the requests on
            the landing pagse of this Website.<span>&nbsp; </span>
            Any of these limited requests for Your information that are not accompanied by
            a payment method and Membership registration, do not constitute creating an
            account, becoming a Member, or a Membership – it is just an expression of Your
            interest to receive certain notifications or access to certain content.
            <span>&nbsp;</span>
          </p>
          <p>
            If at any time you would like Us to remove or delete any information You have
            provided to Us, promptly submit a written request to{" "}
            <a href="mailto:support@globalscalingacademy.com">
              support@globalscalingacademy.com
            </a>
            .
          </p>
          <p>
            <strong>
              <span>ORDERS AND PAYMENTS.</span>
            </strong>
          </p>
          <p>
            Users of Website that successfully complete a Membership application and make
            payment will receive an onboarding email with the description of community
            membership activities in order to encourage them to effectively integrate into
            the community.
            <span>&nbsp;&nbsp;</span>You may be permitted to use the Website to order
            and/or pay for Your selected Membership option. To do so, You must supply
            certain information relevant to your transaction and payment method,
            including, without limitation: Your credit card number, the expiration date of
            Your credit card, the full name on Your credit card, and/or Your billing
            address.
            <span>&nbsp; </span>You may also be asked to provide a delivery address for
            certain Materials and Services requested. <span>&nbsp;</span>Once You have
            submitted Your payment method information, We will authorize the payment and
            send You a confirmation email.<span>&nbsp; </span>
          </p>
          <p>
            You agree to pay any applicable fees related to Your Membership which are
            described fully on Our [MEMBERSHIP PAGE
            www.globalscalingacademy.com/membership].
            <span>&nbsp;&nbsp;</span>Members are responsible for paying the taxes on all
            payments.
            <span>&nbsp; </span>We may suspend or terminate Your account and/or access to
            Our Services and Your Membership if Your payment is late and/or Your offered
            payment method (e.g., Credit Card, Debit Card, PayPal, Bank Transfer) cannot
            be processed.
            <span>&nbsp; </span>By providing a payment method, You expressly authorize Us
            and/or Our third-party payment processor to charge the applicable fees on said
            payment method as well as taxes and other charges incurred thereto at regular
            intervals, all of which depend on Your particular Membership option and
            utilized Services.
          </p>
          <p>
            You agree that We may charge any unpaid amounts to Your provided payment
            method and/or send You a bill for such unpaid fees.
          </p>
          <p>
            In the case of Your Membership in the Community, Members are not entitled to a
            refund once they have logged on to, and accessed, the online platform, which
            contains Our Materials and Services.
            <span>&nbsp; </span>In the case of &nbsp;any additional services available
            within the Community, Members will be required to make payment in advance to
            secure their enrollment in the programs or services.<span>&nbsp; </span>If a
            Member chooses to abandon participation in any additional program or service
            for any reason prior to completing 50% of the program, they may be eligible
            for a 50% refund, at Our discretion.<span>&nbsp; </span>In order for Us to
            help You out, please contact Us directly at{" "}
            <a href="mailto:support@globalscalingacademy.com">
              support@globalscalingacademy.com
            </a>
            . <span>&nbsp;</span>If however a Member has completed more than 50% of the
            program or services, the Member is not eligible for any refund.
            <span>&nbsp; </span>If we choose to remove You from the program for good
            reason, the same terms will apply.<span>&nbsp; </span>
            Good reason for removal will be factually evaluated by the Global Scaling
            Academy team on a case-by-case basis and may include violation of this
            agreement, the Membership Agreement, or the expected values and behaviors
            within the community.
          </p>
          <p>
            All descriptions, images, features, specifications and prices of Our Services
            are subject to change at any time without notice. The inclusion of any
            Services on the Website does not imply or warrant that such Services will be
            available. We reserve the right, with or without prior notice, to do any one
            or more of the following for any reason: (i) limit the available quantity of
            or discontinue any Materials or Services; (ii) impose conditions on the
            honoring of any coupon, coupon code, promotional code, or other similar
            promotion; (iii) bar You from making or completing any or all transactions;
            and (iv) refuse to provide You with any Materials or Services.
          </p>
          <p>
            <strong>
              <span>ELECTRONIC COMMUNICATIONS.</span>
            </strong>
          </p>
          <p>
            By using the Website and/or the Services provided on or through the Website,
            You consent to receiving electronic communications from Us.
            <span>&nbsp; </span>These electronic communications may include notices about
            applicable fees and charges, transactional information and other information
            concerning or related to the Website and/or Services provided on or through
            the Website, onboarding email sequences, upcoming events email sequences,
            member spotlight email sequences and monthly newsletter email sequences.
            <span>&nbsp; </span>These electronic communications are part of Your
            relationship with Us.
            <span>&nbsp; </span>You agree that any notices, agreements, disclosures or
            other communications that We send You electronically will satisfy any legal
            communication requirements, including that such communications be in writing.
          </p>
          <p>
            <strong>
              <span>PRIVACY POLICY.</span>
            </strong>
          </p>
          <p>
            We respect the information that You provide to Us, and want to be sure You
            fully understand exactly how We use that information.
            <span>&nbsp; </span>So, please review Our Privacy Policy{" "}
            <a href="http://www.globalscaling">www.globalscalingacademy.com/privacy</a>{" "}
            which explains everything.
          </p>
          <p>
            <strong>
              <span>LINKS TO THIRD-PARTY SITES.</span>
            </strong>
          </p>
          <p>
            We think links are convenient, and We sometimes provide links on this Website
            to third-party websites, services and content (collectively “Third Party
            Sites”).<span>&nbsp; </span>If You use these links, You will leave this
            Website. We are not obligated to review any Third Party Sites that You link to
            from this Website, We do not control any of the third-party websites, and We
            are not responsible for any of the third-party websites (or the products,
            services, or content available through any of them). <span>&nbsp;</span>We
            provide these links to you as a convenience, and We do not verify, make any
            representations or take responsibility for such Third-Party Sites, including,
            without limitation, the truthfulness, accuracy, quality or completeness of the
            content, services, links displayed and/or any other activities conducted on or
            through such Third-Party Sites. Therefore, unless specifically stated, we do
            not endorse or make any representations about such Third-Party Sites, any
            information, software, products, services, or materials found there or any
            results that may be obtained from using them. If You decide to access any of
            the third-party websites linked to from this Website, You do this entirely at
            Your own risk and You must follow the privacy policies and terms and
            conditions for those third-party websites. Certain areas of this Website may
            allow You to interact and/or conduct transactions with one or more third-party
            websites, and, if applicable, allow you to configure your privacy settings in
            that third-party website account to permit Your activities on this Website to
            be shared with Your contacts in your third-party site account.
            <span>&nbsp; </span>YOU AGREE THAT WE WILL NOT, UNDER ANY CIRCUMSTANCES, BE
            RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES,
            INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY
            SITES AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED
            THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
            CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR BUSINESS PRACTICES OF
            ANY THIRD-PARTY.
          </p>
          <p>
            For example, Our "Topics" page is a discussion forum and reading list
            aggregator.<span>&nbsp; </span>You are permitted to suggest content that You
            believe would make a valuable addition to one of Our topic area oriented
            reading lists.<span>&nbsp; </span>We allow you to post and moderate all
            content you post, and We alone finally decide what will be posted to Our
            Topics page.
            <span>&nbsp; </span>Any user may make a <span>&nbsp;</span>content or topic
            suggestion to Us.<span>&nbsp; </span>On the Blog and Topics pagse and other
            certain pages on our Website, be advised that you may be directed to leave Our
            <span>&nbsp; </span>Website, and may view a mix of curated and original
            content produced by Our partners.<span>&nbsp; </span>If the Material you are
            accessing on Our Blog or Topics page was originally published on other
            platforms, be advised that We are merely aggregating the content for your
            convenience, providing external links, and displaying lists of available
            Materials from external sources and Third-Party Sites.<span>&nbsp; </span>We
            endeavor to publish Materials with the consent of the true owner or with full
            attribution and encourage You to view the content on the original source page.
          </p>
          <p>
            From time to time, We may post an endorsement of third-party products or
            services on this Website.
            <span>&nbsp; </span>We have a number of partners whose product and service
            offerings may be useful to Our Members and users of this Website. Such
            endorsements may include: small business consultants, legal, accounting,
            marketing, software, web hosting, and other such relevant services, which will
            vary from time to time.
          </p>
          <p>
            Additionally, We link to various social platforms.
            <span>&nbsp; </span>We operate Facebook, Twitter, YouTube, Medium, Linkedin
            and Instagram accounts, to name a few.
            <span>&nbsp; </span>Links to Our social accounts are contained in the footer
            of this Website.<span>&nbsp; </span>On Our Blog and Topics pages, You will
            have the opportunity to share on-page content to Your own social media account
            by clicking the Third-Party Sites from this Website.
            <span>&nbsp;&nbsp;</span>
          </p>
          <p>
            These are just a few examples of Third Party Sites that are not governed by
            these Terms.
          </p>
          <p>
            <strong>
              <span>SUBMISSIONS.</span>
            </strong>
          </p>
          <p>
            Certain areas of this Website (e.g., Our “Blog” page, <span>&nbsp;</span>
            “Topics” page, "Courses," "Groups," "Events," Programs," “Contact Us” page,
            customer ratings and review areas and questionnaires) may permit You to submit
            Feedback, information, data, text, software, messages, or other materials
            (each, a "User Submission").
            <span>&nbsp; </span>You agree that You are solely responsible for all of Your
            User Submissions and that any such User Submission is considered both
            non-confidential and non-proprietary.
            <span>&nbsp; </span>Further, We do not guarantee that You will be able to edit
            or delete any User Submission that You have submitted.
          </p>
          <p>By submitting any User Submission, You are promising Us that:</p>
          <ul>
            <li>
              You own all rights in Your User Submissions (including, without limitation,
              all rights to the reproduction and display of Your User Submissions) or,
              alternatively, You have acquired all necessary rights in Your User
              Submissions to enable You to grant to Us the rights in Your User Submissions
              as described in these Terms;
            </li>
            <li>
              You have paid and will pay in full all license fees, clearance fees, and
              other financial obligations, of any kind, arising from any use or commercial
              exploitation of Your User Submissions;
            </li>
            <li>
              Your User Submissions do not infringe the copyright, trademark, patent,
              trade secret, or other intellectual property rights, privacy rights, or any
              other legal or moral rights of any third party;
            </li>
            <li>
              You voluntarily agree to waive all "moral rights" that You may have in Your
              User Submission;
            </li>
            <li>
              Any information contained in Your User Submission is not known by You to be
              false, inaccurate, misleading;
            </li>
            <li>
              Your User Submission does not violate any law (including, but not limited
              to, those governing export control, consumer protection, unfair competition,
              anti-discrimination, or false advertising);
            </li>
            <li>
              Your User Submission is not, and may not reasonably be considered to be,
              defamatory, libelous, hateful, racially, ethnically, religiously, or
              otherwise biased or offensive, unlawfully threatening, or unlawfully
              harassing to any individual, partnership, or corporation, vulgar,
              pornographic, obscene, or invasive of another's privacy;
            </li>
            <li>
              You were not and will not be compensated or granted any consideration by any
              third party for submitting Your User Submission;
            </li>
            <li>
              Your User Submission does not incorporate materials from a third-party
              website, or addresses, email addresses, contact information, or phone
              numbers (other than Your own);
            </li>
            <li>
              Your User Submission does not contain any viruses, worms, spyware, adware,
              or other potentially damaging programs or files;
            </li>
            <li>
              Your User Submission does not contain any information that You consider
              confidential, proprietary, or personal; and
            </li>
            <li>
              Your User Submission does not contain or constitute any unsolicited or
              unauthorized advertising, promotional materials, junk mail, spam, chain
              letters, pyramid schemes, or any other form of solicitation.
            </li>
          </ul>
          <p>
            By submitting a User Submission, You grant to Us an irrevocable, perpetual,
            transferable, non-exclusive, fully-paid, worldwide, royalty-free license
            (sublicensable through multiple tiers) to:
          </p>
          <ul>
            <li>
              Use, distribute, reproduce, modify, adapt, publish, translate, publicly
              perform, and publicly display Your User Submissions (or any modification
              thereto), in whole or in part, in any format or medium now known or later
              developed;
            </li>
            <li>
              Use (and permit others to use) Your User Submission in any manner and for
              any purpose (including, without limitation, commercial purposes) that We
              deem appropriate in Our sole discretion (including, without limitation, to
              incorporate Your User Submission or any modification thereto, in whole or in
              part, into any technology, product, or service);
            </li>
            <li>
              Display advertisements in connection with Your User Submissions and to use
              Your User Submissions for advertising and promotional purposes.
            </li>
          </ul>
          <p>
            Any guest bloggers submitting a User Submission will be required to submit the
            post to Us for internal review, and if We approve, We will post it to this
            Website.<span>&nbsp; </span>As a user of this Website, even if You are not a
            Member, You may comment on a Blog post or other certain areas of this Website,
            either by providing Your name, or, in certain cases, You may be permitted to
            comment anonymously.<span>&nbsp; </span>We reserve the right to remove
            comments from any user or Member at any time for any reason without notice.
          </p>
          <p>
            We may, but are not obligated to, pre-screen User Submissions or monitor any
            area of this Website through which User Submissions may be submitted.{" "}
            <span>&nbsp;</span>We are not required to host, display, or distribute any
            User Submissions on or through this Website and may remove at any time or
            refuse any User Submissions for any reason. We are not responsible for any
            loss, theft, or damage of any kind to any User Submissions. Further, You agree
            that We may freely disclose Your User Submission to any third party absent any
            obligation of confidence on the part of the recipient.
          </p>
          <p>
            <strong>
              <span>UNAUTHORIZED ACTIVITIES.</span>
            </strong>
          </p>
          <p>
            To be clear, We authorize Your use of this Website only for Permitted
            Purposes.<span>&nbsp; </span>Any other use of this Website beyond the
            Permitted Purposes is prohibited and, therefore, constitutes unauthorized use
            of this Website.
            <span>&nbsp; </span>This is because as between You and Us, all rights in this
            Website remain Our property.
          </p>
          <p>
            Unauthorized use of this Website may result in violation of various United
            States and international copyright laws.
            <span>&nbsp; </span>Because We prefer keeping this relationship drama-free, We
            want to give You examples of things to avoid.
            <span>&nbsp; </span>So, unless You have written permission from Us stating
            otherwise, You are not authorized to use this Website in any of the following
            ways (these are examples only and the list below is not a complete list of
            everything that You are not permitted to do):
          </p>
          <ul>
            <li>
              For any public or commercial purpose which includes use of this Website on
              another site or through a networked computer environment;
            </li>
            <li>
              In a manner that modifies, publicly displays, publicly performs, reproduces
              or distributes any of this Website;
            </li>
            <li>
              In a manner that violates any local, state, national, foreign, or
              international statute, regulation, rule, order, treaty, or other law;
            </li>
            <li>To stalk, harass, or harm another individual;</li>
            <li>
              To impersonate any person or entity or otherwise misrepresent Your
              affiliation with a person or entity;
            </li>
            <li>
              To interfere with or disrupt this Website or servers or networks involved
              with the delivery of the Services;
            </li>
            <li>
              To use any data mining, robots, or similar data gathering or extraction
              methods in connection with this Website; or
            </li>
            <li>
              Attempt to gain unauthorized access to any portion of this Website or any
              other accounts, computer systems, or networks used to provide the Services,
              whether through hacking, password mining, or any other means.
            </li>
          </ul>
          <p>
            You agree to hire attorneys to defend Us if You violate these Terms and that
            violation results in a problem for Us.
            <span>&nbsp; </span>You also agree to pay any damages that We may end up
            having to pay as a result of Your violation.
            <span>&nbsp; </span>You alone are responsible for any violation of these Terms
            by You.<span>&nbsp; </span>We reserve the right to assume the exclusive
            defense and control of any matter otherwise subject to indemnification by You
            and, in such case, You agree to cooperate with Our defense of such claim.
          </p>
          <p>
            <strong>
              <span>PROPRIETARY RIGHTS.</span>
            </strong>
          </p>
          <p>
            "Global Scaling Academy" is a trademark that belongs to Us.
            <span>&nbsp; </span>Other trademarks, names and logos on this Website are the
            property of their respective owners.
          </p>
          <p>
            Unless otherwise specified in these Terms, all Materials, including the
            arrangement of them on this Website are Our sole property, copyright © 2017.
            <span>&nbsp; </span>All rights not expressly granted herein are reserved.
            <span>&nbsp; </span>Except as otherwise required or limited by applicable law,
            any reproduction, distribution, modification, retransmission, or publication
            of any copyrighted material is strictly prohibited without the express written
            consent of the copyright owner or license.
            <span>&nbsp; </span>
          </p>
          <p>
            The Website and related documentation that are provided to you are "Commercial
            Items", as that term is defined at 48 C.F.R. §2.101, consisting of "Commercial
            Computer Software" and "Commercial Computer Software Documentation", as such
            terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable.
            Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4,
            as applicable, if you are a government entity, the Commercial Computer
            Software and Commercial Computer Software Documentation are being licensed to
            U.S. Government end users (a) only as Commercial Items and (b) with only those
            rights as are granted to all other end users pursuant to the terms and
            conditions herein.
            <span>&nbsp; </span>Unpublished-rights are reserved under the copyright laws
            of the United States.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </p>
          <p>
            <strong>
              <span>INTELLECTUAL PROPERTY INFRINGEMENT.</span>
            </strong>
          </p>
          <p>
            We respect the intellectual property rights of others and encourage You to do
            the same. Accordingly, We have a policy of removing User Submissions that
            violate intellectual property rights of others, suspending access to this
            Website (or any portion thereof) to any user who uses this Website in
            violation of someone’s intellectual property rights, and/or terminating in
            appropriate circumstances the account of any user who uses the this Website in
            violation of someone’s intellectual property rights.
          </p>
          <p>
            Pursuant to Title 17 of the United States Code, Section 512, We have
            implemented procedures for receiving written notification of claimed copyright
            infringement and for processing such claims in accordance with such law. If
            You believe Your copyright or other intellectual property right is being
            infringed by a user of this Website, please provide written notice to Our DMCA
            Agent for notice of claims of infringement:
          </p>
          <p>Global Scaling Academy™</p>
          <p>
            Attn: Jeffrey D. Abbot DMCA Agent
            <span>&nbsp; </span>
          </p>
          <p>1067 Amarillo Dr</p>
          <p>Palo Alto, CA 94303</p>
          <p>(650) 485-3350</p>
          <p>Email: info@globalscalingacademy.com</p>
          <p>To be sure the matter is handled immediately, Your written notice must:</p>
          <ul>
            <li>Contain Your physical or electronic signature;</li>
            <li>
              Identify the copyrighted work or other intellectual property alleged to have
              been infringed;
            </li>
            <li>
              Identify the allegedly infringing material in a sufficiently precise manner
              to allow Us to locate that material;
            </li>
            <li>
              Contain adequate information by which We can contact You (including postal
              address, telephone number, and e-mail address);
            </li>
            <li>
              Contain a statement that You have a good faith belief that use of the
              copyrighted material or other intellectual property is not authorized by the
              owner, the owner's agent or the law;
            </li>
            <li>
              Contain a statement that the information in the written notice is accurate;
              and
            </li>
            <li>
              Contain statement, under penalty of perjury, that You are authorized to act
              on behalf of the copyright or other intellectual property right owner.
            </li>
          </ul>
          <p>
            Unless the notice pertains to copyright or other intellectual property
            infringement, Our DCMA Agent will be unable to address the listed concern.
          </p>
          <p>
            <span>Submitting a DMCA Counter-Notification</span>
          </p>
          <p>
            We will notify You that We have removed or disabled access to
            copyright-protected material that You provided, if such removal is pursuant to
            a validly received DMCA take-down notice. In response, You may provide Our
            DCMA Agent with a written counter-notification that includes the following
            information:
          </p>
          <ol>
            <li>
              <span> </span>Your physical or electronic signature;
            </li>
            <li>
              <span> </span>Identification of the material that has been removed or to
              which access has been disabled, and the location at which the material
              appeared before it was removed or access to it was disabled;
            </li>
            <li>
              <span> </span>A statement from You under the penalty of perjury, that You
              have a good faith belief that the material was removed or disabled as a
              result of a mistake or misidentification of the material to be removed or
              disabled; and
            </li>
            <li>
              <span> </span>Your name, physical address and telephone number, and a
              statement that You consent to the jurisdiction of a court for the judicial
              district in which Your physical address is located, or if Your physical
              address is outside of the United States, for any judicial district in which
              We may be located, and that You will accept service of process from the
              person who provided notification of allegedly infringing material or an
              agent of such person.
            </li>
          </ol>
          <p>
            <span>Termination of Repeat Infringers</span>
          </p>
          <p>
            We reserve the right, in Our sole discretion, to terminate the account or
            access of any user of this Website or Service who is the subject of repeated
            DMCA or other infringement notifications.
            <span>&nbsp; </span>
          </p>
          <p>
            <strong>
              <span>DISCLAIMER OF WARRANTIES.</span>
            </strong>
          </p>
          <p>
            THIS WEBSITE IS PROVIDED "AS IS" AND "WITH ALL FAULTS" AND THE ENTIRE RISK AS
            TO THE QUALITY AND PERFORMANCE OF THIS WEBSITE IS WITH YOU.
          </p>
          <p>
            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED OR
            STATUTORY) WITH RESPECT TO THIS WEBSITE, WHICH INCLUDES BUT IS NOT LIMITED TO,
            ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL
            PROPERTY RIGHTS.
          </p>
          <p>
            THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE WEBSITE IS FREE OF PROBLEMS.
            <span>&nbsp; </span>
            Without limiting the generality of the foregoing, We make no warranty that
            this Website will meet Your requirements or that this Website{" "}
            <span>&nbsp;</span>its Services will be uninterrupted, timely, secure, or
            error free or that defects in this Website will be corrected. We make no
            warranty as to the results that may be obtained from the use of this Website,
            the accuracy or reliability of any information obtained through this Website,
            that user information or Services transmitted in connection with your use of
            the Website (including as part of payment) will be successfully, accurately,
            or securely transmitted or received, or that any particular Services ordered
            will be available. <span>&nbsp;</span>No advice or information, whether oral
            or written, obtained by You through this Website or from Us or Our
            subsidiaries/other affiliated companies shall create any warranty. We disclaim
            all equitable indemnities.
          </p>
          <p>
            <strong>
              <span>LIMITATION OF LIABILITY.</span>
            </strong>
          </p>
          <p>
            WE SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR USE OF THE
            WEBSITE OR FROM YOUR DISPLAYING, COPYING, OR DOWNLOADING ANY MATERIALS TO OR
            FROM THIS WEBSITE.
            <span>&nbsp; </span>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
            EVENT SHALL WE BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF
            DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) HOWEVER ARISING, EVEN
            IF WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.
          </p>
          <p>
            <strong>
              <span>LOCAL LAWS; EXPORT CONTROL.</span>
            </strong>
          </p>
          <p>
            We control and operate this Website from Our headquarters in the United States
            of America and the entirety of this Website may not be appropriate or
            available for use in other locations.
            <span>&nbsp; </span>If You use this Website outside the United States of
            America, You are solely responsible for following applicable local laws.
          </p>
          <p>
            <strong>
              <span>FEEDBACK.</span>
            </strong>
          </p>
          <p>
            Any submissions by You to Us (e.g., comments, questions, suggestions,
            materials – collectively, “Feedback”) through any communication whatsoever
            (e.g., website, community, call, fax, email) will be treated as both
            non-confidential and non-proprietary.
            <span>&nbsp; </span>You hereby assign all right, title, and interest in, and
            We are free to use, without any attribution or compensation to You, any ideas,
            know-how, concepts, techniques, or other intellectual property and proprietary
            rights contained in the Feedback, whether or not patentable, for any purpose
            whatsoever, including but not limited to, developing, manufacturing, having
            manufactured, licensing, marketing, and selling, directly or indirectly,
            products and services using such Feedback.
            <span>&nbsp; </span>To the extent the foregoing assignment of rights, title
            and interest in and Your Feedback is prohibited by law, You hereby grant Us,
            Our affiliates, subsidiaries and assigns, a non-exclusive, perpetual,
            irrevocable, worldwide, royalty-free, fully paid-up license (including the
            right to sublicense through multiple tiers) to (a) to fully use, practice and
            exploit those non-assignable rights, title and interest, including, but not
            limited to, the right to use, reproduce, adapt, publicly perform, publicly
            display, modify, prepare derivative works, publish, transmit and distribute
            your Feedback, or any portion thereof, in any form, medium or distribution
            method now known or hereafter existing, known or developed, for any purpose,
            including without limitation, any purpose relating to the marketing,
            advertising and promotion of this Website and Services; and (b) authorize any
            such use by others of Your Feedback, or any portion thereof, in the same
            manner in Our sole discretion. You understand and agree that We are not
            obligated to use, display, reproduce, or distribute any such ideas, know-how,
            concepts, or techniques contained in the Feedback, and You have no right to
            compel such use, display, reproduction, or distribution.
          </p>
          <p>
            <strong>
              <span>LANGUAGE.</span>
            </strong>
          </p>
          <p>
            The Parties hereto have expressly required that this agreement and all
            documents and notices relating thereto be drafted in the English language.
          </p>
          <p>
            <strong>
              <span>GENERAL.</span>
            </strong>
          </p>
          <p>
            We think direct communication resolves most issues – if We feel that You are
            not complying with these Terms, We will tell You.
            <span>&nbsp; </span>We will even provide You with recommended necessary
            corrective action(s) because We value this relationship.
          </p>
          <p>
            However, certain violations of these Terms, as determined by Us, may require
            immediate termination of Your access to this Website without prior notice to
            You.<span>&nbsp; </span>The Federal Arbitration Act, California state law and
            applicable U.S. federal law, without regard to the choice or conflicts of law
            provisions, will govern these Terms.<span>&nbsp; </span>Foreign laws do not
            apply.
            <span>&nbsp; </span>Except for disputes subject to arbitration as described
            above, any disputes relating to these Terms or this Website will be heard in
            the courts located in San Mateo County, California.<span>&nbsp; </span>If any
            of these Terms are deemed inconsistent with applicable law, then such term(s)
            shall be interpreted to reflect the intentions of the parties, and no other
            terms will be modified.<span>&nbsp; </span>By choosing not to enforce any of
            these Terms, We are not waiving Our rights.
            <span>&nbsp; </span>These Terms are the entire agreement between You and Us
            and, therefore, supersede all prior or contemporaneous negotiations,
            discussions or agreements between You and Us about this Website.
            <span>&nbsp; </span>The proprietary rights, disclaimer of warranties,
            representations made by You, indemnities, limitations of liability and general
            provisions shall survive any termination of these Terms.
          </p>
          <p>
            <strong>
              <span>CALIFORNIA CONSUMER NOTICE.</span>
            </strong>
          </p>
          <p>
            Under California Civil Code Section 1789.3, California users are entitled to
            the following consumer rights notice: this Website and Service are provided by
            Global Scaling Academy, Global Scaling Academy™<span>&nbsp; </span>1067
            Amarillo Dr, Palo Alto CA 94303&nbsp;(650) 485-3350 . <span>&nbsp;</span>If
            You have a question or complaint regarding the Website or Service, please
            contact customer service at info@globalscalingacademy.com. <span>&nbsp;</span>
            You may also contact Us by writing Global Scaling Academy, Global Scaling
            Academy™
            <span>&nbsp; 1067 Amarillo Dr, Palo Alto CA 94303</span>
            <span>&nbsp;</span>(650) 485-3350 . California residents may reach the
            Complaint Assistance Unit of the Division of Consumer Services of the
            California Department of Consumer Affairs by post at 1625 North Market Blvd.,
            Sacramento, CA 95834 or by telephone at (916) 445-1254 or (800) 952-5210 or
            Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.
          </p>
          <p>
            <strong>
              <span>CONTACT US.</span>
            </strong>
          </p>
          <p>
            If You have any questions about these Terms or otherwise need to contact Us
            for any reason, You can reach Us electronically at{" "}
            <a href="mailto:support@globalscalingacademy.com">
              support@globalscalingacademy.com
            </a>{" "}
            or at 1067 Amarillo Dr, Palo Alto CA 94303 &nbsp;(650) 485-3350.
          </p>
          <p>&nbsp;</p>
        </div>
      </Container>
    </PageWrapper>
  );
}

export default RenderForm;
