import React, {memo, useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {getCountryStatistics} from "../../../api/admin";
import {getData} from "./colors";

const Country = (submissionType) => {
  const [country, setCountry] = useState([]);
  useEffect(() => {
    getCountryStatistics(submissionType).then((data) => {
      setCountry(data);
    });
  }, [submissionType]);

  const countryData = (country && country.data) || [];
  const labels = countryData.map((c) => c._id) || [];
  const data = countryData.map((c) => c.count) || [];

  return <Pie data={getData({labels, data})} type="pie" />;
};

export default memo(Country);
