import * as Yup from "yup";
import formSchema from "../../common/form-validation";

const adminFormSchema = Yup.object().shape({
  ...formSchema,
  essentialElement1: Yup.number().required("This is a required field"),
  essentialElement2: Yup.number().required("This is a required field"),
  essentialElement1Interpretation: Yup.string(),
  essentialElement2Interpretation: Yup.string(),
  marketSizeInterpretation: Yup.string(),
  grossMarginInterpretation: Yup.string(),
  marketFitInterpretation: Yup.string(),
  organizationalScalabilityInterpretation: Yup.string(),
  operationalScalabilityInterpretation: Yup.string(),
  adminComment: Yup.string(),
});

export default adminFormSchema;
