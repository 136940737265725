import React from "react";

const AdminCanvas = ({pointsDistribution}) => {
  const getColors = () => {
    if (pointsDistribution) {
      const {
        essentialElement1,
        essentialElement2,
        marketFit,
        marketSize,
        grossMargin,
        organizationalScalability,
        operationalScalability,
      } = pointsDistribution;

      const canva = {
        red: essentialElement1,
        green: essentialElement2,
        orange: marketSize,
        purple: grossMargin,
        pink: marketFit,
        yellow: operationalScalability,
        blue: organizationalScalability,
      };
      return canva;
    }
  };

  const canva = getColors();

  const canvasOpacity = (color, index) => {
    if (canva) {
      const howMany = canva[color];
      if (index[0] <= howMany) {
        return {opacity: 1};
      } else {
        return {opacity: 0.15};
      }
    }
  };

  const attr = {
    width: "72",
    height: "72",
    strokeOpacity: 0.15,
  };

  const recAttr = {
    green: {
      ...attr,
      className: "cls-1",
    },
    pink: {
      ...attr,
      className: "cls-6",
    },
    purple: {
      ...attr,
      className: "cls-7",
    },
    orange: {
      ...attr,
      className: "cls-9",
    },
    blue: {
      ...attr,
      className: "cls-10",
    },
    yellow: {
      ...attr,
      className: "cls-11",
    },
    red: {
      ...attr,
      className: "cls-12",
    },
  };

  return (
    <div style={{width: "60%", margin: "30px auto"}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 750 750"
        style={{overflow: "initial"}}
      >
        <rect
          x="0"
          y="0"
          width="722"
          height="722"
          stroke="black"
          strokeWidth="3"
          strokeOpacity="0.7"
          fill="none"
        />
        <g id="Layer_2" data-name="Layer 2">
          <g id="GSA">
            <g id="greengroup">
              <g className="green" style={canvasOpacity("green", [1, 2])}>
                <rect x="361" y="217" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [3])}>
                <rect x="361" y="145" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [4])}>
                <rect x="289" y="289" {...recAttr.green} />
                <rect x="289" y="217" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [5])}>
                <rect x="217" y="217" {...recAttr.green} />
                <rect x="217" y="289" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [6])}>
                <rect x="289" y="73" {...recAttr.green} />
                <rect x="361" y="73" {...recAttr.green} />
                <rect x="289" y="145" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [7])}>
                <rect x="217" y="73" {...recAttr.green} />
                <rect x="217" y="145" {...recAttr.green} />
                <rect x="145" y="217" {...recAttr.green} />
                <rect x="145" y="289" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [8])}>
                <rect x="145" y="73" {...recAttr.green} />
                <rect x="145" y="145" {...recAttr.green} />
                <rect x="73" y="217" {...recAttr.green} />
                <rect x="73" y="289" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [9])}>
                <rect x="361" y="1" {...recAttr.green} />
                <rect x="289" y="1" {...recAttr.green} />
                <rect x="217" y="1" {...recAttr.green} />
                <rect x="145" y="1" {...recAttr.green} />
                <rect x="73" y="73" {...recAttr.green} />
                <rect x="73" y="145" {...recAttr.green} />
              </g>
              <g className="green" style={canvasOpacity("green", [10])}>
                <rect x="1" y="1" {...recAttr.green} />
                <rect x="1" y="73" {...recAttr.green} />
                <rect x="1" y="145" {...recAttr.green} />
                <rect x="1" y="217" {...recAttr.green} />
                <rect x="1" y="289" {...recAttr.green} />
                <rect x="73" y="1" {...recAttr.green} />
              </g>
            </g>
            <g id="pinkgroup">
              <g className="pink" style={canvasOpacity("pink", [1, 2])}>
                <rect x="433" y="217" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [3])}>
                <rect x="505" y="217" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [4])}>
                <rect x="433" y="145" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [5])}>
                <rect x="505" y="145" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [6])}>
                <rect x="577" y="145" {...recAttr.pink} />
                <rect x="649" y="145" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [7])}>
                <rect x="433" y="73" {...recAttr.pink} />
                <rect x="505" y="73" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [8])}>
                <rect x="577" y="73" {...recAttr.pink} />
                <rect x="649" y="73" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [9])}>
                <rect x="433" y="1" {...recAttr.pink} />
                <rect x="505" y="1" {...recAttr.pink} />
              </g>
              <g className="pink" style={canvasOpacity("pink", [10])}>
                <rect x="577" y="1" {...recAttr.pink} />
                <rect x="649" y="1" {...recAttr.pink} />
              </g>
            </g>
            <g id="purplegroup">
              <g className="purple" style={canvasOpacity("purple", [1, 2])}>
                <rect x="361" y="289" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [3, 4])}>
                <rect x="433" y="289" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [5])}>
                <rect x="505" y="289" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [6])}>
                <rect x="577" y="289" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [7])}>
                <rect x="649" y="289" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [8])}>
                <rect x="577" y="217" {...recAttr.purple} />
              </g>
              <g className="purple" style={canvasOpacity("purple", [9, 10])}>
                <rect x="649" y="217" {...recAttr.purple} />
              </g>
            </g>
            <g id="orangegroup">
              <g className="orange" style={canvasOpacity("orange", [1])}>
                <rect x="361" y="361" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [2, 3])}>
                <rect x="433" y="361" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [4])}>
                <rect x="505" y="361" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [5])}>
                <rect x="433" y="433" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [6])}>
                <rect x="505" y="433" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [7])}>
                <rect x="433" y="505" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [8])}>
                <rect x="505" y="505" {...recAttr.orange} />
                <rect x="433" y="577" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [9])}>
                <rect x="505" y="577" {...recAttr.orange} />
                <rect x="433" y="649" {...recAttr.orange} />
              </g>
              <g className="orange" style={canvasOpacity("orange", [10])}>
                <rect x="505" y="649" {...recAttr.orange} />
              </g>
            </g>
            <g id="bluegroup">
              <g className="blue" style={canvasOpacity("blue", [1, 2, 3])}>
                <rect x="577" y="361" {...recAttr.blue} />
              </g>
              <g className="blue" style={canvasOpacity("blue", [4, 5])}>
                <rect x="577" y="433" {...recAttr.blue} />
              </g>
              <g className="blue" style={canvasOpacity("blue", [6, 7])}>
                <rect x="577" y="505" {...recAttr.blue} />
              </g>
              <g className="blue" style={canvasOpacity("blue", [8])}>
                <rect x="577" y="577" {...recAttr.blue} />
              </g>
              <g className="blue" style={canvasOpacity("blue", [9, 10])}>
                <rect x="577" y="649" {...recAttr.blue} />
              </g>
            </g>
            <g id="yellowgroup">
              <g className="yellow" style={canvasOpacity("yellow", [1, 2, 3])}>
                <rect x="649" y="361" {...recAttr.yellow} />
              </g>
              <g className="yellow" style={canvasOpacity("yellow", [4, 5])}>
                <rect x="649" y="433" {...recAttr.yellow} />
              </g>
              <g className="yellow" style={canvasOpacity("yellow", [6, 7])}>
                <rect x="649" y="505" {...recAttr.yellow} />
              </g>
              <g className="yellow" style={canvasOpacity("yellow", [8])}>
                <rect x="649" y="577" {...recAttr.yellow} />
              </g>
              <g className="yellow" style={canvasOpacity("yellow", [9, 10])}>
                <rect x="649" y="649" {...recAttr.yellow} />
              </g>
            </g>
            <g id="redgroup">
              <g className="red" style={canvasOpacity("red", [1, 2])}>
                <rect x="361" y="433" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [3])}>
                <rect x="361" y="505" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [4])}>
                <rect x="361" y="577" {...recAttr.red} />
                <rect x="361" y="649" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [5])}>
                <rect x="289" y="361" {...recAttr.red} />
                <rect x="289" y="433" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [6])}>
                <rect x="289" y="505" {...recAttr.red} />
                <rect x="289" y="577" {...recAttr.red} />
                <rect x="289" y="649" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [7])}>
                <rect x="217" y="361" {...recAttr.red} />
                <rect x="217" y="433" {...recAttr.red} />
                <rect x="217" y="505" {...recAttr.red} />
                <rect x="217" y="577" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [8])}>
                <rect x="217" y="649" {...recAttr.red} />
                <rect x="145" y="361" {...recAttr.red} />
                <rect x="145" y="433" {...recAttr.red} />
                <rect x="145" y="505" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [9])}>
                <rect x="145" y="577" {...recAttr.red} />
                <rect x="145" y="649" {...recAttr.red} />
                <rect x="73" y="361" {...recAttr.red} />
                <rect x="73" y="433" {...recAttr.red} />
                <rect x="73" y="505" {...recAttr.red} />
                <rect x="73" y="577" {...recAttr.red} />
              </g>
              <g className="red" style={canvasOpacity("red", [10])}>
                <rect x="73" y="649" {...recAttr.red} />
                <rect x="1" y="361" {...recAttr.red} />
                <rect x="1" y="433" {...recAttr.red} />
                <rect x="1" y="505" {...recAttr.red} />
                <rect x="1" y="577" {...recAttr.red} />
                <rect x="1" y="649" {...recAttr.red} />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default AdminCanvas;
