import React from "react";
import {Switch} from "react-router-dom";
import AdminAllSubmissionPage from "../../pages/admin/AllSubmissionsPage";
import AdminAddCompaniesPage from "../../pages/admin/AddCompaniesPage";
import AdminStatisticsPage from "../../pages/admin/Statistics";
import AdminSignInPage from "../../pages/admin/SigninPage";

import {useAuth0} from "../auth/auth0-provider-with-history";
import PrivateRoute from "../auth/PrivateRoute";
import Dashboard from "../../pages/admin/Dashboard";

const AdminRoutes = () => {
  const {loading, isAuthenticated, loginWithRedirect, user} = useAuth0();

  return (
    <Switch>
      <PrivateRoute exact path="/admin/login" component={AdminSignInPage} />
      <PrivateRoute
        exact
        path="/dashboard/all-submissions"
        render={() => <Dashboard PageToRender={<AdminAllSubmissionPage />} />}
      />
      <PrivateRoute
        exact
        path="/dashboard/add-companies"
        render={(props) => (
          <Dashboard PageToRender={<AdminAddCompaniesPage {...props} />} />
        )}
      />
      <PrivateRoute
        exact
        path="/dashboard/statistics"
        render={() => <Dashboard PageToRender={<AdminStatisticsPage />} />}
      />
    </Switch>
  );
};

export default AdminRoutes;
