import {getRandomColorArr} from "../../common/helper";

export const getData = ({labels, data}) => {
  return {
    labels: labels || [],
    datasets: [
      {
        data: data || [],
        backgroundColor: getRandomColorArr(data.length),
        borderWidth: 1,
      },
    ],
  };
};
