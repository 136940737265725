import React from "react";

const ClientCanvas = ({pointsDistribution}) => {
  const getColors = () => {
    if (pointsDistribution) {
      const {
        essentialElement1,
        essentialElement2,
        marketFit,
        marketSize,
        grossMargin,
        organizationalScalability,
        operationalScalability,
      } = pointsDistribution;

      const canva = {
        red: Math.floor(essentialElement1),
        green: essentialElement2,
        orange: marketSize,
        purple: grossMargin,
        pink: marketFit,
        yellow: operationalScalability,
        blue: organizationalScalability,
      };
      return canva;
    }
  };

  const canva = getColors();

  const canvaOpacity = (color, index) => {
    if (canva) {
      const howMany = canva[color];
      if (index <= howMany) {
        return {opacity: 1};
      } else {
        return {opacity: 0.15};
      }
    }
  };

  return (
    <div style={{width: "60%", margin: "30px auto"}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 750 750"
        style={{overflow: "initial"}}
      >
        <rect
          x="0"
          y="0"
          width="722"
          height="722"
          stroke="black"
          strokeWidth="3"
          strokeOpacity="0.7"
          fill="none"
        />
        <g id="Layer_2" data-name="Layer 2">
          <g id="GSA">
            <g id="greengroup">
              <g className="green" style={canvaOpacity("green", 1)}>
                <rect
                  id="green1fill"
                  className="cls-1"
                  x="361"
                  y="217"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber1">
                  <circle
                    id="groupNumberCircle"
                    className="cls-2"
                    cx="389.74"
                    cy="260.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(386.78 264.3)">
                    1
                  </text>
                </g>
              </g>
              <g className="green" style={canvaOpacity("green", 2)}>
                <polygon
                  id="green2fill"
                  className="cls-1"
                  strokeOpacity={1}
                  points="289 217 289 289 217 289 217 361 289 361 361 361 361 289 361 217 289 217"
                />
                <g id="green2border" className="cls-4">
                  <line className="cls-5" x1="361" y1="289" x2="289" y2="289" />
                  <line className="cls-5" x1="289" y1="361" x2="289" y2="289" />
                </g>
                <g id="groupnumber2">
                  <circle
                    id="groupNumberCircle-2"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="245.74"
                    cy="332.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(241.69 336.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="green" style={canvaOpacity("green", 3)}>
                <polygon
                  id="green3fill"
                  className="cls-1"
                  strokeOpacity={1}
                  points="361 73 361 145 289 145 217 145 217 217 217 289 289 289 289 217 361 217 433 217 433 145 433 73 361 73"
                />
                <g id="green3border" className="cls-4">
                  <line className="cls-5" x1="289" y1="145" x2="289" y2="217" />
                  <line className="cls-5" x1="217" y1="217" x2="289" y2="217" />
                  <line className="cls-5" x1="361" y1="145" x2="361" y2="217" />
                  <line className="cls-5" x1="433" y1="145" x2="361" y2="145" />
                </g>
                <g id="groupnumber3">
                  <circle
                    id="groupNumberCircle-3"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="245.74"
                    cy="260.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(241.88 264.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="green" style={canvaOpacity("green", 4)}>
                <polygon
                  id="green4fill"
                  className="cls-1"
                  strokeOpacity={1}
                  points="289 73 217 73 145 73 145 145 145 217 73 217 73 289 73 361 145 361 217 361 217 289 217 217 217 145 289 145 361 145 361 73 289 73"
                />
                <g id="green4border" className="cls-4">
                  <line className="cls-5" x1="217" y1="73" x2="217" y2="145" />
                  <line className="cls-5" x1="289" y1="73" x2="289" y2="145" />
                  <line className="cls-5" x1="145" y1="145" x2="217" y2="145" />
                  <line className="cls-5" x1="145" y1="217" x2="217" y2="217" />
                  <line className="cls-5" x1="145" y1="361" x2="145" y2="217" />
                  <line className="cls-5" x1="73" y1="289" x2="217" y2="289" />
                </g>
                <g id="groupnumber4">
                  <circle
                    id="groupNumberCircle-4"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="101.86"
                    cy="332.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(97.44 336.19)">
                    4
                  </text>
                </g>
              </g>
              <g className="green" style={canvaOpacity("green", 5)}>
                <polygon
                  id="green5fill"
                  className="cls-1"
                  strokeOpacity={1}
                  points="361 1 289 1 217 1 145 1 73 1 1 1 1 73 1 145 1 217 1 289 1 361 73 361 73 289 73 217 145 217 145 145 145 73 217 73 289 73 361 73 433 73 433 1 361 1"
                />
                <g id="green5border" className="cls-4">
                  <line className="cls-5" x1="1" y1="289" x2="73" y2="289" />
                  <line className="cls-5" x1="1" y1="217" x2="73" y2="217" />
                  <line className="cls-5" x1="1" y1="145" x2="145" y2="145" />
                  <line className="cls-5" x1="1" y1="73" x2="145" y2="73" />
                  <line className="cls-5" x1="73" y1="217" x2="73" y2="1" />
                  <line className="cls-5" x1="145" y1="1" x2="145" y2="73" />
                  <line className="cls-5" x1="217" y1="73" x2="217" y2="1" />
                  <line className="cls-5" x1="289" y1="1" x2="289" y2="73" />
                  <line className="cls-5" x1="361" y1="1" x2="361" y2="73" />
                </g>
                <g id="groupnumber5">
                  <circle
                    id="groupNumberCircle-5"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="29.86"
                    cy="332.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(25.76 336.3)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="pinkgroup">
              <g className="pink" style={canvaOpacity("pink", 1)}>
                <rect
                  id="pink1fill"
                  className="cls-6"
                  x="433"
                  y="217"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber1-2" data-name="groupnumber1">
                  <circle
                    id="groupNumberCircle-6"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.86"
                    cy="260.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(458.9 264.3)">
                    1
                  </text>
                </g>
              </g>
              <g className="pink" style={canvaOpacity("pink", 2)}>
                <rect
                  id="pink2fill"
                  className="cls-6"
                  x="505"
                  y="217"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber2-2" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-7"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="533.74"
                    cy="260.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(529.69 264.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="pink" style={canvaOpacity("pink", 3)}>
                <polygon
                  id="pink3fill"
                  className="cls-6"
                  strokeOpacity={1}
                  points="577 145 505 145 433 145 433 217 505 217 577 217 649 217 649 145 577 145"
                />
                <g id="pink3border" className="cls-4">
                  <line className="cls-5" x1="505" y1="217" x2="505" y2="145" />
                  <line className="cls-5" x1="577" y1="145" x2="577" y2="217" />
                </g>
                <g id="groupnumber3-2" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-8"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.86"
                    cy="188.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(458 192.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="pink" style={canvaOpacity("pink", 4)}>
                <polygon
                  id="pink4-2"
                  data-name="pink4"
                  className="cls-6"
                  strokeOpacity={1}
                  points="577 73 505 73 505 1 433 1 433 73 433 145 505 145 577 145 649 145 649 73 577 73"
                />
                <g id="pink4border" className="cls-4">
                  <line className="cls-5" x1="433" y1="73" x2="505" y2="73" />
                  <line className="cls-5" x1="505" y1="145" x2="505" y2="73" />
                  <line className="cls-5" x1="577" y1="73" x2="577" y2="145" />
                </g>
                <g id="groupnumber4-2" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-9"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.74"
                    cy="116.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(457.32 120.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="pink" style={canvaOpacity("pink", 5)}>
                <polygon
                  id="pink5fill"
                  className="cls-6"
                  strokeOpacity={1}
                  points="649 1 577 1 505 1 505 73 577 73 649 73 649 145 649 217 721 217 721 145 721 73 721 1 649 1"
                />
                <g id="pink5border" className="cls-4">
                  <line className="cls-5" x1="649" y1="1" x2="649" y2="73" />
                  <line className="cls-5" x1="577" y1="1" x2="577" y2="73" />
                  <line className="cls-5" x1="721" y1="73" x2="649" y2="73" />
                  <line className="cls-5" x1="649" y1="145" x2="721" y2="145" />
                </g>
                <g id="groupnumber5-2" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-10"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.74"
                    cy="188.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.64 192.3)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="purplegroup">
              <g className="purple" style={canvaOpacity("purple", 2)}>
                <polygon
                  id="purple2fill"
                  className="cls-7"
                  strokeOpacity={1}
                  points="433 289 361 289 361 361 433 361 505 361 505 289 433 289"
                />
                <g id="purple2border" className="cls-4">
                  <line className="cls-5" x1="433" y1="289" x2="433" y2="361" />
                </g>
                <g id="groupnumber2-3" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-11"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="389.86"
                    cy="332.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(385.81 336.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="purple" style={canvaOpacity("purple", 3)}>
                <rect
                  id="purple3fill"
                  className="cls-7"
                  x="505"
                  y="289"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber3-3" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-12"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="533.74"
                    cy="332.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(529.88 336.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="purple" style={canvaOpacity("purple", 4)}>
                <polygon
                  id="pruple4fill"
                  className="cls-7"
                  strokeOpacity={1}
                  points="577 217 577 289 577 361 649 361 649 289 649 217 577 217"
                />
                <g id="purple4border" className="cls-4">
                  <line className="cls-5" x1="577" y1="289" x2="649" y2="289" />
                </g>
                <g id="groupnumber4-3" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-13"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="605.74"
                    cy="332.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(601.32 336.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="purple" style={canvaOpacity("purple", 5)}>
                <polygon
                  id="purple5fill"
                  className="cls-7"
                  strokeOpacity={1}
                  points="649 217 649 289 649 361 721 361 721 289 721 217 649 217"
                />
                <g id="purple5border">
                  <line className="cls-8" x1="721" y1="289" x2="649" y2="289" />
                </g>
                <g id="groupnumber5-3" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-14"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.86"
                    cy="332.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.76 336.19)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="orangegroup">
              <g className="orange" style={canvaOpacity("orange", 1)}>
                <rect
                  id="orange1fill"
                  className="cls-9"
                  x="361"
                  y="361"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber1-3" data-name="groupnumber1">
                  <circle
                    id="groupNumberCircle-15"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="389.74"
                    cy="404.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(386.78 408.3)">
                    1
                  </text>
                </g>
              </g>
              <g className="orange" style={canvaOpacity("orange", 2)}>
                <rect
                  id="orange2fill"
                  className="cls-9"
                  x="433"
                  y="361"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber2-4" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-16"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.74"
                    cy="404.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(457.69 408.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="orange" style={canvaOpacity("orange", 3)}>
                <polygon
                  id="orange3fill"
                  className="cls-9"
                  strokeOpacity={1}
                  points="505 361 505 433 505 505 577 505 577 433 577 361 505 361"
                />
                <g id="orange3border" className="cls-4">
                  <line className="cls-5" x1="577" y1="433" x2="505" y2="433" />
                </g>
                <g id="groupnumber3-4" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-17"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="533.74"
                    cy="476.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(529.88 480.19)">
                    3
                  </text>
                </g>
              </g>
              <g className="orange" style={canvaOpacity("orange", 4)}>
                <polygon
                  id="orange4fill"
                  className="cls-9"
                  strokeOpacity={1}
                  points="505 505 505 433 433 433 433 505 433 577 433 649 505 649 505 577 577 577 577 505 505 505"
                />
                <g id="orange4border" className="cls-4">
                  <line className="cls-5" x1="433" y1="505" x2="505" y2="505" />
                  <line className="cls-5" x1="505" y1="577" x2="433" y2="577" />
                  <line className="cls-5" x1="505" y1="505" x2="505" y2="577" />
                </g>
                <g id="groupnumber4-4" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-18"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.86"
                    cy="620.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(457.44 624.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="orange" style={canvaOpacity("orange", 5)}>
                <polygon
                  id="orange5fill"
                  className="cls-9"
                  strokeOpacity={1}
                  points="505 577 505 649 433 649 433 721 505 721 577 721 577 649 577 577 505 577"
                />
                <g id="orange5border" className="cls-4">
                  <line className="cls-5" x1="577" y1="649" x2="505" y2="649" />
                  <line className="cls-5" x1="505" y1="721" x2="505" y2="649" />
                </g>
                <g id="groupnumber5-4" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-19"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="461.86"
                    cy="692.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(457.76 696.19)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="bluegroup">
              <g className="blue" style={canvaOpacity("blue", 2)}>
                <rect
                  id="blue2fill"
                  className="cls-10"
                  x="577"
                  y="361"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber2-5" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-20"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="605.74"
                    cy="404.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(601.69 408.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="blue" style={canvaOpacity("blue", 3)}>
                <rect
                  id="blue3fill"
                  className="cls-10"
                  x="577"
                  y="433"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber3-5" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-21"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="605.74"
                    cy="476.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(601.88 480.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="blue" style={canvaOpacity("blue", 4)}>
                <rect
                  id="blue4fill"
                  className="cls-10"
                  x="577"
                  y="505"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber4-5" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-22"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="605.74"
                    cy="548.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(601.32 552.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="blue" style={canvaOpacity("blue", 5)}>
                <polygon
                  id="blue5fill"
                  className="cls-10"
                  strokeOpacity={1}
                  points="577 577 577 649 577 721 649 721 649 649 649 577 577 577"
                />
                <g id="blue5border" className="cls-4">
                  <line className="cls-5" x1="577" y1="649" x2="649" y2="649" />
                </g>
                <g id="groupnumber5-5" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-23"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="605.86"
                    cy="692.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(601.76 696.19)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="yellowgroup">
              <g className="yellow" style={canvaOpacity("yellow", 2)}>
                <rect
                  id="yellow2fill"
                  className="cls-11"
                  x="649"
                  y="361"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber2-6" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-24"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.74"
                    cy="404.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.69 408.3)">
                    2
                  </text>
                </g>
              </g>
              <g className="yellow" style={canvaOpacity("yellow", 3)}>
                <rect
                  id="yellow3fill"
                  className="cls-11"
                  x="649"
                  y="433"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber3-6" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-25"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.74"
                    cy="476.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.88 480.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="yellow" style={canvaOpacity("yellow", 4)}>
                <rect
                  id="yellow4fill"
                  className="cls-11"
                  x="649"
                  y="505"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g id="groupnumber4-6" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-26"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.74"
                    cy="548.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.32 552.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="yellow" style={canvaOpacity("yellow", 5)}>
                <polygon
                  id="yellow5fill"
                  className="cls-11"
                  strokeOpacity={1}
                  points="649 577 649 649 649 721 721 721 721 649 721 577 649 577"
                />
                <g id="yellow5border" className="cls-4">
                  <line className="cls-5" x1="649" y1="649" x2="721" y2="649" />
                </g>
                <g id="groupnumber5-6" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-27"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="677.74"
                    cy="692.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(673.64 696.19)">
                    5
                  </text>
                </g>
              </g>
            </g>
            <g id="redgroup">
              <g className="red" style={canvaOpacity("red", 1)}>
                <rect
                  id="red1fill"
                  className="cls-12"
                  x="361"
                  y="433"
                  width="72"
                  height="72"
                  strokeOpacity={1}
                />
                <g className="cls-4">
                  <line className="cls-5" x1="289" y1="433" x2="289" y2="361" />
                  <line className="cls-5" x1="361" y1="433" x2="289" y2="433" />
                </g>
                <g id="groupnumber1-7" data-name="groupnumber1">
                  <circle
                    id="groupNumberCircle-28"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="389.74"
                    cy="476.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(386.6 480.19)">
                    1
                  </text>
                </g>
              </g>
              <g className="red" style={canvaOpacity("red", 2)}>
                <polygon
                  id="red2fill"
                  className="cls-12"
                  strokeOpacity={1}
                  points="289 361 217 361 217 433 289 433 289 505 361 505 361 433 361 361 289 361"
                />
                <g className="cls-4">
                  <line className="cls-5" x1="289" y1="433" x2="289" y2="361" />
                  <line className="cls-5" x1="361" y1="433" x2="289" y2="433" />
                </g>
                <g id="groupnumber2-7" data-name="groupnumber2">
                  <circle
                    id="groupNumberCircle-28"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="317.74"
                    cy="476.14"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(313.69 480.19)">
                    2
                  </text>
                </g>
              </g>
              <g className="red" style={canvaOpacity("red", 3)}>
                <polygon
                  id="red3fill"
                  className="cls-12"
                  strokeOpacity={1}
                  points="361 505 289 505 289 433 217 433 217 505 217 577 289 577 361 577 361 649 433 649 433 577 433 505 361 505"
                />
                <g id="red3border" className="cls-4">
                  <line className="cls-5" x1="217" y1="505" x2="289" y2="505" />
                  <line className="cls-5" x1="289" y1="577" x2="289" y2="505" />
                  <line className="cls-5" x1="361" y1="577" x2="361" y2="505" />
                  <line className="cls-5" x1="433" y1="577" x2="361" y2="577" />
                </g>
                <g id="groupnumber3-7" data-name="groupnumber3">
                  <circle
                    id="groupNumberCircle-29"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="245.74"
                    cy="548.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(241.88 552.3)">
                    3
                  </text>
                </g>
              </g>
              <g className="red" style={canvaOpacity("red", 4)}>
                <polygon
                  id="red4fill"
                  className="cls-12"
                  strokeOpacity={1}
                  points="289 577 217 577 217 505 217 433 217 361 145 361 73 361 73 433 73 505 145 505 145 577 145 649 217 649 289 649 361 649 361 577 289 577"
                />
                <g id="red4border" className="cls-4">
                  <line className="cls-5" x1="145" y1="577" x2="217" y2="577" />
                  <line className="cls-5" x1="217" y1="649" x2="217" y2="577" />
                  <line className="cls-5" x1="289" y1="649" x2="289" y2="577" />
                  <line className="cls-5" x1="145" y1="505" x2="217" y2="505" />
                  <line className="cls-5" x1="73" y1="433" x2="217" y2="433" />
                  <line className="cls-5" x1="145" y1="505" x2="145" y2="361" />
                </g>
                <g id="groupnumber4-7" data-name="groupnumber4">
                  <circle
                    id="groupNumberCircle-30"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="173.74"
                    cy="620.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(169.32 624.3)">
                    4
                  </text>
                </g>
              </g>
              <g className="red" style={canvaOpacity("red", 5)}>
                <polygon
                  id="red5fill"
                  className="cls-12"
                  strokeOpacity={1}
                  points="361 649 289 649 217 649 145 649 145 577 145 505 73 505 73 433 73 361 1 361 1 433 1 505 1 577 1 649 1 721 73 721 145 721 217 721 289 721 361 721 433 721 433 649 361 649"
                />
                <g id="red5border" className="cls-13">
                  <line className="cls-5" x1="1" y1="433" x2="73" y2="433" />
                  <line className="cls-5" x1="1" y1="505" x2="73" y2="505" />
                  <line className="cls-5" x1="1" y1="577" x2="145" y2="577" />
                  <line className="cls-5" x1="1" y1="649" x2="145" y2="649" />
                  <line className="cls-5" x1="73" y1="505" x2="73" y2="721" />
                  <line className="cls-5" x1="145" y1="649" x2="145" y2="721" />
                  <line className="cls-5" x1="217" y1="649" x2="217" y2="721" />
                  <line className="cls-5" x1="289" y1="649" x2="289" y2="721" />
                  <line className="cls-5" x1="361" y1="649" x2="361" y2="721" />
                </g>
                <g id="groupnumber5-7" data-name="groupnumber5">
                  <circle
                    id="groupNumberCircle-31"
                    data-name="groupNumberCircle"
                    className="cls-2"
                    cx="29.74"
                    cy="692.26"
                    r="14.34"
                  />
                  <text className="cls-3" transform="translate(25.64 696.3)">
                    5
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ClientCanvas;
