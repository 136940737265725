import React, {memo, useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {getCategoryStatistics} from "../../../api/admin";
import {getData} from "./colors";

const Category = (submissionType) => {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    getCategoryStatistics(submissionType).then((data) => {
      setCategory(data);
    });
  }, [submissionType]);

  const categoryData = (category && category.data) || [];
  const labels = categoryData.map((c) => c._id) || [];
  const data = categoryData.map((c) => c.count) || [];

  return <Pie data={getData({labels, data})} type="pie" />;
};

export default memo(Category);
