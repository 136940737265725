import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Auth0Provider} from "./utils/auth/auth0-provider-with-history";
import history from "./utils/history";
import WebFont from "webfontloader";

import "./index.css";
import "./assets/fonts/gibson-cufonfonts-webfont/style.css";

WebFont.load({
  custom: {
    families: ["Gibson Regular", "Gibson Light", "sans-serif"],
    urls: ["/assets/fonts/gibson-cufonfonts-webfont/style.css"],
  },
});

const onRedirectCallback = (appState) => {
  history.push(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUDIENCE}
    onRedirectCallback={onRedirectCallback}
    redirect_uri={window.location.origin + "/dashboard/add-companies"}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
