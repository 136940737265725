import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function ClientNavbar({children, dark}) {
  return (
    <div>
      <Header darkLogo={dark} />
      {children}
      <Footer darkTheme={dark} />
    </div>
  );
}

export default ClientNavbar;
