import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularSpinner from "./CircularSpinner";
import logo from "../../../assets/images/BlitzscalingAcademyLogo.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Loading: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
  },
  Logo: {
    height: "150px",
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.Loading}>
      <img src={logo} className={classes.Logo} alt="logo" />
      <CircularSpinner />
    </Typography>
  );
};

export default Loading;
