import React from "react";
import {Route, Switch} from "react-router-dom";
import BlitzscalabilityResultsPage from "../../pages/client/form-results-page/form-results-page";
import FormPage from "../../pages/client/form-page/form";
import ThankYouPage from "../../pages/ThankYou";
import TermsAndConditionsPage from "../../pages/client/terms-and-conditions/terms-and-conditions";

function ClientRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/blitzscalability-results/:submissionID"
        component={BlitzscalabilityResultsPage}
      />
      <Route exact path="/blitzscalability-form" component={FormPage} />
      <Route exact path="/thank-you" component={ThankYouPage} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
    </Switch>
  );
}

export default ClientRoutes;
