import React from "react";

const PrimaryButton = ({title, ...props}) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        {...props}
        style={{
          padding: "15px 32px",
          lineHeight: 1,
          fontWeight: 600,
          letterSpacing: "1px",
          cursor: "pointer",
          textAlign: "center",
          border: "1px solid #3d5467",
          color: "#fff",
          fontFamily: "Gibson SemiBold",
          backgroundColor: "#3d5467",
          borderRadius: "5px",
          fontSize: "17px",
          width: "230px",
        }}
      >
        {title}
      </button>
    </div>
  );
};

export default PrimaryButton;
