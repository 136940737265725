import React from "react";

export default {
  sectionHeader: {
    header1: {
      title: "Company Information",
    },
    header2: {
      title: "Growth Factor Evaluation",
      subtitle:
        "The blitzscaling framework evaluates the scalability of a company across 7 variables, referred to as growth enhancers and growth limiters.  We further break them down into “Essential Elements” and Necessary Elements.  All good businesses should score highly on the necessary variables in order to be scalable, but these alone are not sufficient to justify blitzscaling.  For a business to be “blitzscalable,” it must unquestionably score highly on the 2 essential elements.",
    },
  },
  firstName: {
    name: "firstName",
    title: "First Name*",
  },
  lastName: {
    name: "lastName",
    title: "Last Name*",
  },
  email: {
    name: "email",
    title: "Email*",
  },
  companyName: {
    name: "companyName",
    title: "Company Name*",
  },
  website: {
    name: "website",
    title: "Website URL",
  },
  companyRole: {
    name: "companyRole",
    title: "Your role in the company*",
    options: [
      {key: "CEO", value: "CEO"},
      {key: "CTO", value: "CTO"},
      {key: "CMO", value: "CMO"},
      {key: "CRO", value: "CRO"},
      {key: "Non Executive Co-founder", value: "Non Executive Co-founder"},
      {key: "Investor", value: "Investor"},
      {key: "Other", value: "Other"},
    ],
  },
  companyStage: {
    name: "companyStage",
    multiQuestions: true,
    title: "What is the current stage of your company?",
    items: [
      {
        name: "companySize",
        subtitle: "Number of employees*",
        optionsField: true,
        options: [
          {key: "1-9", value: "1-9"},
          {key: "10-99", value: "10-99"},
          {key: "100-999", value: "100-999"},
          {key: "1000-9999", value: "1000-9999"},
          {key: "10000+", value: "10,000+"},
        ],
      },
      {
        name: "revenue",
        subtitle: " Revenue stage*",
        optionsField: true,
        options: [
          {key: "Pre-Product", value: "Pre-Product"},
          {key: "Pre-Revenue", value: "Pre-Revenue"},
          {key: "Post-Revenue", value: "Post-Revenue"},
        ],
      },
    ],
  },
  support: {
    name: "support",
    title: "Do you want additional support scaling your company?*",
    options: [
      {key: "yes", value: "Yes"},
      {key: "no", value: "No"},
    ],
  },
  fundingType: {
    name: "fundingType",
    title: "Funding Type*",
    options: [
      {key: "Pre Seed", value: "Pre Seed"},
      {key: "Seed", value: "Seed"},
      {key: "Series A", value: "Series A"},
      {key: "Series B", value: "Series B"},
      {key: "Series C", value: "Series C"},
      {key: "Series D+", value: "Series D+"},
    ],
  },
  businessModel: {
    name: "businessModel",
    multiQuestions: true,
    title: "How would you describe your business?",
    items: [
      {
        name: "category",
        subtitle: "Category*",
        optionsField: true,
        options: [
          {
            key: "B2B",
            value: "B2B: you sell a product or service to business customers",
          },
          {
            key: "B2C",
            value: "B2C: your goal is to have millions of customers / users",
          },
          {
            key: "B2B2C",
            value:
              "B2B2C: you provide a product or service to businesses who sell it to consumers\n",
          },
          {key: "Other", value: "Other"},
        ],
      },
      {
        name: "business",
        subtitle: "Type of business*",
        optionsField: true,
        options: [
          {
            key: "E-commerce",
            value: "E-commerce",
          },
          {
            key: "Marketplace",
            value: "Marketplace",
          },
          {
            key: "SaaS",
            value: "SaaS",
          },
          {
            key: "Hardware",
            value: "Hardware",
          },
          {
            key: "Market Network",
            value: "Market Network",
          },
          {
            key: "Social Network",
            value: "Social Network",
          },
          {
            key: "Other",
            value: "Other",
          },
        ],
      },
    ],
  },
  country: {
    name: "country",
    title: "Country*",
  },
};
