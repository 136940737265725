import React, {useEffect, useState} from "react";
import MaterialTable, {MTableToolbar} from "material-table";
import {Button, FormControl, Grid, MenuItem, Select} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Info from "@material-ui/icons/Info";
import Refresh from "@material-ui/icons/Refresh";
import tableIcons from "../tableIcons";
import Checkbox from "@material-ui/core/Checkbox";
import {getLocalDateTime} from "../../common/helper";
import {adminAddEditCompany, deleteSubmission, getSearchResult} from "../../../api/admin";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import "./CompanyInfoTable.css";
import DialogAlert from "../../widgets/alert/DialogAlert";

const submissionFilter = [
  {value: "client", label: "Client Submission"},
  {value: "admin", label: "Admin Submission"},
];

const CompanyInfoTable = ({
  setOpen,
  setRowData,
  setCompany,
  rowData,
  data,
  setData,
  company,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const tableRef = React.createRef();
  const [submissionType, setSubmissionType] = useState("all");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    (async function renderTable() {
      return tableRef.current && tableRef.current.onQueryChange();
    })();
  }, [submissionType]);

  const handleShowCompanyChange = ({event, row}) => {
    delete row.tableData;
    delete row._v;
    adminAddEditCompany({
      requestData: {
        ...row,
        publiclyVisible: event.target.checked,
      },
      parsed: true,
    })
      .then((response) => {
        // something wrong
        if (response === undefined || response === null || !response) {
          setOpenDialog(true);
          return;
        }
        history.push("/dashboard/all-submissions");
      })
      .catch((error) => {
        console.log(error);
        setOpenDialog(true);
      });
  };

  return (
    <>
      <MaterialTable
        title="All Submissions"
        tableRef={tableRef}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
          actionsColumnIndex: -1,
          search: true,
          sorting: true,
          rowStyle: {
            backgroundColor: "#EEE",
          },
        }}
        icons={tableIcons}
        columns={[
          {
            title: "Date Scored",
            field: "dateCreated",
            render: (data) => <p>{getLocalDateTime(data.dateCreated)}</p>,
          },
          {title: "Report Type", field: "submissionType"},
          {title: "Company Name", field: "companyName"},
          {title: "Category", field: "category"},
          {title: "Business Type", field: "business"},
          {title: "Org Stage", field: "companySize"},
          {title: "Rev Stage", field: "revenue"},
          {title: "Funding Type", field: "fundingType"},
          {
            title: "Total Score",
            field: "blitzScalabilityScore",
            type: "numeric",
          },
          {
            title: "Report",
            field: "_id",
            render: (rowData) => (
              <Button
                target="_blank"
                href={`/blitzscalability-results/${rowData._id}`}
                color="primary"
              >
                Result page
              </Button>
            ),
          },
          {
            title: "Display Company",
            field: "publiclyVisible",
            render: (rowData) => {
              if (rowData.submissionType === "client") {
                return <Checkbox disabled indeterminate />;
              } else if (rowData.submissionType === "admin") {
                return (
                  <Checkbox
                    defaultChecked={rowData.publiclyVisible || false}
                    color="primary"
                    onChange={(event) => handleShowCompanyChange({event, row: rowData})}
                  />
                );
              } else {
                return null;
              }
            },
          },
        ]}
        components={{
          Toolbar: (props) => {
            return (
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <MTableToolbar {...props} />
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={submissionType}
                      onChange={(event) => setSubmissionType(event.target.value)}
                      displayEmpty
                      className={classes.formControl}
                      inputProps={{"aria-label": "Without label"}}
                    >
                      <MenuItem key={0} value="all">
                        All
                      </MenuItem>
                      {submissionFilter.map((submissionType, index) => (
                        <MenuItem key={index + 1} value={submissionType.value}>
                          {submissionType.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            );
          },
          isFreeAction: true,
        }}
        actions={[
          (rowData) => ({
            icon: () => <EditIcon />,
            tooltip: "Edit Company Info",
            hidden: rowData.submissionType === "client",
            onClick: (event, rowData) => {
              // redirect to add-companies page to edit current company information
              history.push({
                pathname: "/dashboard/add-companies",
                state: {data: rowData},
              });
            },
          }),
          {
            icon: () => <Info />,
            tooltip: "Show Company Details",
            onClick: (event, rowData) => {
              setOpen(true);
              setCompany(rowData);
              setRowData(rowData);
            },
          },
          {
            icon: () => <Refresh />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
        ]}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);
              deleteSubmission(oldData).then((result) => {
                resolve(result);
              });
            }).catch((error) => {
              console.log(error);
            }),
        }}
        data={(query) => getTableData(query, submissionType)}
      />
      {openDialog ? (
        <DialogAlert
          title="Error"
          content="Something is wrong, please try again!"
          button="Ok"
          openDialog={openDialog}
        />
      ) : null}
    </>
  );
};

const getTableData = (query, type) => {
  return new Promise((resolve, reject) => {
    let submissionType = "";
    switch (type) {
      case "admin":
        submissionType = "admin";
        break;
      case "client":
        submissionType = "client";
        break;
      default:
        break;
    }
    query.orderBy = query.orderBy && query.orderBy.field;

    getSearchResult({submissionType, ...query})
      .then((result) => {
        const {submissions, totalCount, page} = result.data;
        if (totalCount && totalCount > 0) {
          resolve({
            data: submissions,
            page: page,
            totalCount: totalCount,
          });
        } else {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        }
      })
      .catch((error) => {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      });
  });
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

export default React.memo(CompanyInfoTable);
